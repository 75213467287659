<template>
  <div class="table-filter-container-search" style="position: relative">
      <div style="display: flex;justify-content: space-between">
        <div>
          <Select v-if="!DataSourceGroup.mq.includes(taskInfo.sourceType)" prefix="ios-search"
                  @on-change="handleChangeFilterType(db,$event)" style="width:200px;margin-right: 10px"
                  v-model="filterTable[theDb].tableFilterType">
            <Option value="autoCreated">{{ $t('dai-chuang-jian-biao') }}</Option>
            <Option value="noCreate">{{ $t('yi-cun-zai-biao') }}</Option>
            <Option value="hasPk">{{ $t('you-zhu-jian-biao') }}</Option>
            <Option value="noPk">{{ $t('wu-zhu-jian-biao') }}</Option>
            <Option value="hasSelected">{{ $t('yi-xuan-ze-de-biao') }}</Option>
            <Option value="notSelected">{{ $t('wei-xuan-ze-de-biao') }}</Option>
            <Option value="column">{{ $t('lie-ming-shai-xuan') }}</Option>
            <Option value="differPk">{{ $t('zhu-jian-wei-dui-qi') }}</Option>
          </Select>
          <div style="display: inline-block;margin-right: 10px;" v-if="filterTable[theDb].tableFilterType==='column'">
            <Input v-model="filterTable[theDb].tableFilterColumn" :placeholder="$t('qing-tian-ru-shai-xuan-biao-de-lie-ming')"
                   style="width: 150px"/>
            <pd-button :loading="loading" style="margin-left: 5px" type="primary"
                       @click="handleTableFilter(theDb,db.sourceSchema,db)">{{ $t('que-ding') }}
            </pd-button>
          </div>
          <Input prefix="ios-search" v-model="filterTable[theDb].tableFilterName" :placeholder="$t('shai-xuan-biao')"
                 style="width: 230px" @on-change="handleTableFilter(theDb,db.sourceSchema,db)"/>
        </div>
        <div>
          <div style="float: right;display: inline-block;margin-top: 6px"
               v-if="!tableDisabled&&!(taskInfo.type==='CHECK'||taskInfo.type==='REVISE'||taskInfo.type==='STRUCT_MIGRATION'||taskInfo.type==='MIGRATION'&&!taskInfo.mode.synchronize)&&(!isMQ(taskInfo.sourceType)&&!isMQ(taskInfo.sinkType)&&!(isES(taskInfo.sourceType)))">
            <span style="margin-right: 5px;font-weight: 500">{{ $t('action-pi-liang-gou-xuan') }}</span>
            <span style="display: inline-block;width: 360px;vertical-align: top">
                <Checkbox style="width: 70px" label="INSERT" @on-change="handleBatchActionFilter('INSERT', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.INSERT">
                  INSERT
                </Checkbox>
                <Checkbox style="width: 70px" label="UPDATE" @on-change="handleBatchActionFilter('UPDATE', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.UPDATE">
                  UPDATE
                </Checkbox>
                <Checkbox style="width: 70px" label="DELETE" @on-change="handleBatchActionFilter('DELETE', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.DELETE">
                  DELETE
                </Checkbox>
                <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" style="width: 70px" label="CREATE"
                          @on-change="handleBatchActionFilter('CREATE', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.CREATE">
                  CREATE
                </Checkbox>
                <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" style="width: 70px" label="ALTER"
                          @on-change="handleBatchActionFilter('ALTER', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.ALTER">
                  ALTER
                </Checkbox>
                <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" style="width: 70px" label="RENAME"
                          @on-change="handleBatchActionFilter('RENAME', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.RENAME">
                  RENAME
                </Checkbox>
                <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" style="width: 90px" label="TRUNCATE"
                          @on-change="handleBatchActionFilter('TRUNCATE', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.TRUNCATE">
                  TRUNCATE
                </Checkbox>
                 <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType) && isMySQL(taskInfo.sourceType) && isMySQL(taskInfo.sinkType)" style="width: 90px" label="DROP"
                           @on-change="handleBatchActionFilter('DROP', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.DROP">
                  DROP
                </Checkbox>
            </span>
          </div>
          <span v-if="taskInfo.schemaWhiteListLevel&&DataSourceGroup.mq.includes(taskInfo.sinkType)"
                style="margin-left: 20px;line-height: 32px">{{ $t('mo-ren-topic-taskinfodstmqdefaulttopic-fen-qu-shu-taskinfodstmqdefaulttopicpartitions', [taskInfo.dstMqDefaultTopic, taskInfo.dstMqDefaultTopicPartitions]) }} <a v-if="!tableDisabled"
                                                                                                                                                                                                                                     @click="handleSetTopic('default')">{{ $t('she-zhi') }}</a></span>
          <Button v-if="DataSourceGroup.mq.includes(taskInfo.sinkType) && !tableDisabled"
                  style="float: right;margin-right: 20px" type="primary" ghost @click="handleSetTopic">
            {{ $t('pi-liang-she-zhi-topic-ming-cheng') }}
          </Button>
          <Button v-if="!DataSourceGroup.mq.includes(taskInfo.sinkType) && !tableDisabled"
                  style="float: right;margin-right: 20px" type="primary" ghost @click="handleShowSetTable">
            {{ $t('pi-liang-she-zhi-mu-biao-biao-ming-cheng') }}
          </Button>
          <Button v-if="theMappingRule.length>1" style="float: right;margin-right: 10px" type="primary"
                  ghost @click="handleShowMappingRule">{{ $t('mu-biao-ying-she-gui-ze') }}
          </Button>
          <pd-button v-if="DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1&&!tableDisabled&&!(taskInfo.type==='CHECK'||taskInfo.type==='REVISE'&&!taskInfo.mode.synchronize)"
                     style="float: right;margin-right: 10px" type="primary" ghost
                     @click="handleSetShards">{{ $t('pi-liang-she-zhi-fen-pian-shu') }}
          </pd-button>
        </div>
      </div>
      <div style="margin-top: 10px" v-if="filterList[theDb]&&filterList[theDb].length>0">
        <span>{{ $t('jian-suo-xiang') }}</span>
        <span class="table-filter-filter-span" v-for="(filter,i) in filterList[theDb]"
              :key="i">{{ Mapping.filterType[filter] }}<Icon type="md-close-circle"
                                                             @click="handleDeleteFilter(i)"/></span>
        <span class="table-filter-filter-clear" @click="handleClearFilter">{{ $t('qing-chu') }}</span>
      </div>
    </div>
</template>
<script>
import { isMQ, isMySQL, isES } from '@/utils';
import Mapping from '@/views/util';
import DataSourceGroup from '@/views/dataSourceGroup';

export default {
  props: {
    filterTable: Object,
    db: Object,
    handleChangeFilterType: Function,
    handleTableFilter: Function,
    taskInfo: Object,
    handleBatchActionFilter: Function,
    handleSetShards: Function,
    handleSetPartitions: Function,
    filterList: Object,
    handleDeleteFilter: Function,
    handleClearFilter: Function,
    handleChangeMigrationType: Function,
    handleDefaultTopic: Function,
    handleSetTopic: Function,
    loading: Boolean,
    handleSetDefaultTopic: Function,
    handleShowMappingRule: Function,
    theMappingRule: Array,
    handleBatchSetRedisKey: Function,
    tableDisabled: Boolean,
    handleShowSetTable: Function
  },
  data() {
    return {
      DataSourceGroup,
      Mapping,
      migrationType: 'table',
      theDb: '',
      showUploadPkg: false,
      customPkgFile: null,
      checkAllGroup: ['INSERT', 'UPDATE', 'DELETE']
    };
  },
  created() {
    this.getTheDb();
  },
  methods: {
    isMySQL,
    isMQ,
    isES,
    setCheckAllGroup(data) {
      this.checkAllGroup = data;
    },
    getTheDb() {
      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        this.theDb = this.db.sinkDb;
      } else {
        this.theDb = this.db.sourceDb;
      }
    },
    handleFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024 * 500) {
          this.$Modal.error({
            title: this.$t('wen-jian-guo-da'),
            content: this.$t('wen-jian-da-xiao-ying-bu-chao-guo-500m')
          });

          return false;
        }
        this.customPkgFile = file;
      }
    },
    handleShowUploadPkg() {
      this.customPkgFile = this.taskInfo.customPkgFile;
      this.showUploadPkg = true;
    },
    handleConfirmUpload() {
      this.taskInfo.customPkgFile = this.customPkgFile;
      this.showUploadPkg = false;
    },
    handleCancel() {
      this.showUploadPkg = false;
    },
    handleRemovePkg() {
      this.customPkgFile = null;
    }
  },
  watch: {
    db() {
      this.getTheDb();
    }
  }
};
</script>
<style lang="less">
.clean-data-search-container{
  padding: 14px 20px;
}
.table-filter-container-search {
  padding: 14px 20px;
  background-color: #FAFAFA;
}
</style>
