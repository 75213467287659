<template>
  <div class="operation-log">
    <div class="table-list-layout">
      <div class="table-list">
        <div class="header">
          <Breadcrumb>
            <BreadcrumbItem>{{ $t('cao-zuo-shen-ji') }}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div class="content">
          <div class="option">
            <div class="left" style="align-items: center">
              {{ $t('cao-zuo-shi-jian') }}<DatePicker :editable="false" v-model="timeRange" type="datetimerange" format="yyyy-MM-dd HH:mm" style="width: 266px;margin-left: 10px;margin-right: 10px;"></DatePicker>
              <Select v-model="searchType" style="width:100px;margin-right: 10px;" @on-change="handleChangeSearchType">
                <Option value="user" :label="$t('cao-zuo-ren')">
                  <span>{{ $t('cao-zuo-ren') }}</span>
                </Option>
                <Option value="resourceType" :label="$t('zi-yuan-lei-xing')">
                  <span>{{ $t('zi-yuan-lei-xing') }}</span>
                </Option>
                <Option value="auditType" :label="$t('cao-zuo-dong-zuo')">
                  <span>{{ $t('cao-zuo-dong-zuo') }}</span>
                </Option>
                <Option value="uid" label="uid">
                  <span>uid</span>
                </Option>
              </Select>
              <Input v-if="searchType==='user'" v-model="searchData.userNameLike" style="width: 250px" clearable/>
              <Input v-if="searchType==='uid'" v-model="searchData.uid" style="width: 250px" clearable/>
              <Select v-if="searchType==='resourceType'" v-model="searchData.resourceType" style="width:200px" clearable>
                <Option value="" :label="$t('quan-bu')">{{ $t('quan-bu') }}</Option>
                <Option v-for="item in resourceTypeList" :value="item.resourceType" :key="item.resourceType">
                  {{ item.alias }}
                </Option>
              </Select>
              <Select v-if="searchType==='auditType'" v-model="searchData.auditType" filterable style="width:200px" clearable>
                <Option value="" :label="$t('quan-bu')">{{ $t('quan-bu') }}</Option>
                <Option v-for="item in auditTypeList" :value="item.auditType" :key="item.auditType">
                  {{ item.alias }}
                </Option>
              </Select>
              <Button type="primary" @click="handleRefresh" :loading="refreshLoading" style="margin-left: 10px;">{{ $t('cha-xun') }}</Button>
            </div>
            <div class="right">
              <Button type="default" style="margin-right: 6px" @click="handleRefresh"
                      :loading="refreshLoading">
                {{ $t('shua-xin') }}
              </Button>
            </div>
          </div>
          <div class="table">
            <Table size="small" border :columns="logColumn" :data="logData" >
              <template slot-scope="{row}" slot="resourceValue">
                <a v-if="row.resourceType === 'DATA_JOB'" @click="goJobDetail(row)">{{row.resourceVO && row.resourceVO.resourceFlag}}</a>
                <p v-if="row.resourceType !== 'DATA_JOB'">{{row.resourceVO && row.resourceVO.resourceFlag}}</p>
                <p v-if="row.resourceType === 'PURE_URL'">{{row.operationUri || row.resourceValue}}</p>
              </template>
              <template slot-scope="{ row }" slot="uid">
                <div class="uid">
                  <a @click="handleSearchUid(row)">{{row.uid}}</a>
                  <cc-iconfont :size="12" name="copy" class="copy"
                               @click.native="copyText(`${row.uid}`, $t('fu-zhi-uid-cheng-gong'))"
                               style="margin-left: 3px;"/>
                </div>
              </template>
              <template slot="detail" slot-scope="{row}">
                <div>
                  <a v-if="row.isExistsLog" @click="handleGetAuditDetail(row)">{{$t('cha-kan')}}</a>
                  <a disabled v-if="!row.isExistsLog">{{$t('cha-kan')}}</a>
                </div>
              </template>
            </Table>
          </div>
        </div>
      </div>
      <div class="footer">
        <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>{{ $t('shang-yi-ye') }}</pd-button>
        <span style="margin: 0 10px">{{ $t('di-page-ye', [page]) }}</span>
        <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px" @click="handleNext">
          <Icon type="ios-arrow-forward" style="font-size: 16px"/>{{ $t('xia-yi-ye') }}</pd-button>
      </div>
    </div>
    <Modal v-model="showAuditDetail" :title="$t('cha-kan-ri-zhi')" width="1200" footer-hide>
      <div>
        <div class="log-content">
          <p class="log-content-desc"><span>{{ $t('miao-shu-0') }}</span><span
            class="point-content">{{ auditLogDetail.desc }}</span></p>
          <p class="log-content-desc"><span>{{ $t('lu-jing') }}</span><span
            class="point-content">{{ auditLogDetail.path }}</span></p>
          <div class="detail">
            <p style="padding-bottom: 20px;" v-if="auditLogDetail.content">
            <pre>{{getLogDetail(auditLogDetail.content)}}</pre>
            </p>
            <div v-if="!auditLogDetail.content && selectedRow.uuidKey">
              <p>{{$t('ri-zhi-yi-gui-dang-qing-zhi-fu-wu-qi-cha-kan')}}</p>
              <pre>{{JSON.parse(selectedRow.uuidKey)}}</pre></div>
          </div>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button @click="handleCancel">{{ $t('guan-bi') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  queryAllLog, getLogByUserName, grepOperationLog, ccQueryOperationListCondition
} from '@/services/cc/api/operationLog';
import {
  queryAllLog as rdpQueryAllLog, getLogByUserName as rdpGetLogByUserName, getLogByUid as rdpGetLogByUid, grepOperationLog as rdpGrepOperationLog, rdpQueryOperationListCondition
} from '@/services/cc/api/rdpAudit';
import fecha from 'fecha';
import Mapping from '@/views/util';
import { queryJobById } from '@/services/cc/api/noError';
import copyMixin from '@/mixins/copyMixin';

export default {
  name: 'OperationLog',
  mixins: [copyMixin],
  data() {
    return {
      resourceType: Mapping.resourceType,
      searchType: 'user',
      noMoreData: false,
      refreshLoading: false,
      showAuditDetail: false,
      firstId: 0,
      lastId: 0,
      prevFirst: [],
      page: 1,
      timeRange: [new Date(new Date().getTime() - 24 * 3600 * 1000), new Date()],
      searchData: {
        uid: '',
        userName: '',
        opStart: '',
        opEnd: '',
        // securityLevel:'',
        pageData: {
          startId: 0,
          pageSize: 20
        }
      },
      logColumn: [
        {
          title: this.$t('cao-zuo-zhe'),
          key: 'userName',
          width: 150
        },
        {
          title: 'uid',
          slot: 'uid',
          width: 200
        },
        {
          title: this.$t('cao-zuo-shi-jian'),
          key: 'operateDate',
          width: 200,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.operateDate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('zi-yuan-lei-xing'),
          key: 'resourceTypeDesc',
          width: 150
        },
        {
          title: this.$t('cao-zuo-dong-zuo'),
          key: 'auditTypeDesc',
          width: 200
        },
        {
          title: this.$t('cao-zuo-zi-yuan'),
          slot: 'resourceValue',
          minWidth: 200
        },
        {
          title: this.$t('cao-zuo-di-zhi'),
          key: 'sourceIp',
          width: 150
        },
        {
          title: this.$t('ri-zhi-di-zhi'),
          key: 'logPathWorkerIp',
          width: 150
        },
        {
          title: this.$t('an-quan-deng-ji'),
          key: 'securityLevel',
          width: 150,
          render: (h, params) => h('div', {
            style: {
              color: params.row.securityLevel === 'NORMAL' ? '#19be6b' : params.row.securityLevel === 'HIGH' ? '#ed4014' : ''
            }
          }, params.row.securityLevel === 'NORMAL' ? this.$t('pu-tong') : params.row.securityLevel === 'HIGH' ? this.$t('gao-feng-xian') : ''),
          filterMultiple: false,
          filters: [
            {
              label: this.$t('pu-tong'),
              value: 'NORMAL'
            },
            {
              label: this.$t('gao-feng-xian'),
              value: 'HIGH'
            }
          ],
          filterRemote(value) {
            this.searchData.securityLevel = value[0];
            this.handleSearch();
          }
        },
        {
          title: this.$t('ri-zhi-wei-yi-xin-xi'),
          key: 'uuidKey',
          width: 520
        },
        {
          title: this.$t('e-wai-can-shu'),
          slot: 'detail',
          width: 120,
          fixed: 'right',
          renderHeader: (h) => h('div', [
            h('span', {
              style: {
                fontFamily: 'PingFangSC-Medium',
                fontWeight: '500'
              }
            }, this.$t('e-wai-can-shu')),
            h('Tooltip', {
              style: {
                color: '#888888',
                marginLeft: '8px',
                fontWeight: 400
              },
              props: {
                content: this.$t('zhi-zhi-chi-zai-xian-cha-kan-dang-tian-de-ri-zhi-ru-xu-cha-kan-yi-gui-dang-de-qing'),
                placement: 'left',
                transfer: true
              }
            }, [
              h('Icon', {
                props: {
                  type: 'ios-help-circle-outline'
                }
              })
            ])
          ])
        }
      ],
      logData: [],
      type: 'cc',
      auditTypeList: [],
      resourceTypeList: [],
      auditLogDetail: {},
      selectedRow: {}
    };
  },
  created() {
    if (this.$route.name === 'rdpOperationLog') {
      this.type = 'rdp';
      this.rdpQueryOperationListCondition();
    } else if (this.$route.name === 'operationLog') {
      this.type = 'cc';
      this.ccQueryOperationListCondition();
    }
  },
  mounted() {
    this.handleSearch();
    this.searchData.pageData.pageSize = 20;
  },
  methods: {
    getLogDetail(detail) {
      try {
        return JSON.parse(detail);
      } catch (e) {
        return detail;
      }
    },
    getResourceTypeI18n(type) {
      let alias = '';
      this.resourceTypeList.forEach((resource) => {
        if (type === resource.resourceType) {
          alias = resource.alias;
        }
      });
      return alias;
    },
    getAuditTypeI18n(type) {
      let alias = '';
      this.auditTypeList.forEach((audit) => {
        if (type === audit.auditType) {
          alias = audit.alias;
        }
      });
      return alias;
    },
    handleRefresh() {
      this.page = 1;
      this.firstId = 0;
      this.lastId = 0;
      this.searchData.pageData.startId = 0;
      this.handleSearch();
    },
    ccQueryOperationListCondition() {
      ccQueryOperationListCondition().then((res) => {
        if (res.data.success) {
          this.auditTypeList = res.data.data.auditTypeVOS;
          this.resourceTypeList = res.data.data.resourceTypeVOS;
        }
      });
    },
    rdpQueryOperationListCondition() {
      rdpQueryOperationListCondition().then((res) => {
        if (res.data.success) {
          this.auditTypeList = res.data.data.auditTypeVOS;
          this.resourceTypeList = res.data.data.resourceTypeVOS;
        }
      });
    },
    handleSearch(type) {
      this.refreshLoading = true;
      if (this.timeRange.length > 0) {
        this.searchData.opStart = this.timeRange[0] && fecha.format(new Date(new Date(this.timeRange[0]).getTime() - 8 * 3600 * 1000), 'YYYY-MM-DDTHH:mm:ss.SSS');
        this.searchData.opEnd = this.timeRange[1] && fecha.format(new Date(new Date(this.timeRange[1].getTime() - 8 * 3600 * 1000)), 'YYYY-MM-DDTHH:mm:ss.SSS');
      } else {
        this.searchData.opStart = '';
        this.searchData.opEnd = '';
      }
      this.searchData.pageData.pageSize = 20;
      if (this.searchData.uid) {
        let apiName = queryAllLog;
        if (this.type === 'rdp') {
          apiName = rdpQueryAllLog;
        }
        apiName(this.searchData).then((res) => {
          if (res.data.code === '1') {
            this.logData = res.data.data;
            if (type === 'next') {
              if (!this.prevFirst[this.page - 1]) {
                this.prevFirst.push(this.firstId);
              }
            }
            this.firstId = this.logData[0].id;
            this.lastId = this.logData[this.logData.length - 1].id;
          }
          this.refreshLoading = false;
          this.noMoreData = res.data.data.length < this.searchData.pageData.pageSize;
        }).catch(() => {
          this.refreshLoading = false;
        });
      } else if (this.searchData.userNameLike) {
        let apiName = queryAllLog;
        if (this.type === 'rdp') {
          apiName = rdpQueryAllLog;
        }
        apiName(this.searchData).then((res) => {
          if (res.data.code === '1') {
            this.logData = res.data.data;
            if (type === 'next') {
              if (!this.prevFirst[this.page - 1]) {
                this.prevFirst.push(this.firstId);
              }
            }
            this.firstId = this.logData[0].id;
            this.lastId = this.logData[this.logData.length - 1].id;
          }
          this.refreshLoading = false;
          this.noMoreData = res.data.data.length < this.searchData.pageData.pageSize;
        }).catch(() => {
          this.refreshLoading = false;
        });
      } else {
        let apiName = queryAllLog;
        if (this.type === 'rdp') {
          apiName = rdpQueryAllLog;
        }
        apiName(this.searchData).then((res) => {
          if (res.data.code === '1') {
            this.logData = res.data.data;
            if (type === 'next') {
              if (!this.prevFirst[this.page - 1]) {
                this.prevFirst.push(this.firstId);
              }
            }
            this.firstId = this.logData[0].id;
            this.lastId = this.logData[this.logData.length - 1].id;
          }
          this.refreshLoading = false;
          this.noMoreData = res.data.data.length < this.searchData.pageData.pageSize;
        }).catch(() => {
          this.refreshLoading = false;
        });
      }
    },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.searchData.pageData.startId = startId;
      this.handleSearch('prev');
    },
    handleNext() {
      this.searchData.pageData.startId = this.lastId;
      this.handleSearch('next');
      this.page++;
    },
    handleChangeSize() {
      this.handleSearch('next');
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchData = {
        uid: '',
        userName: '',
        opStart: '',
        opEnd: '',
        // securityLevel:'',
        pageData: {
          startId: 0,
          pageSize: 20
        }
      };
    },
    handleSearchUid(row) {
      this.searchType = 'uid';
      this.searchData.uid = row.uid;
      this.handleSearch();
    },
    handleGetAuditDetail(row) {
      let apiName = null;
      if (this.type === 'cc') {
        apiName = grepOperationLog;
      } else if (this.type === 'rdp') {
        apiName = rdpGrepOperationLog;
      }
      apiName({
        operationId: row.id
      }).then((res) => {
        if (res.data.success) {
          console.log('res', res);
          this.auditLogDetail = res.data.data;
          this.selectedRow = row;
          this.showAuditDetail = true;
        }
      });
    },
    goJobDetail(row) {
      queryJobById({ jobId: row.resourceVO.id }).then((res) => {
        if (res.data.code === '1') {
          this.$router.push({ path: `/data/job/${row.resourceVO.id}` });
        } else {
          this.$Modal.info({
            title: this.$t('tiao-zhuan-shi-bai'),
            content: this.$t('gai-ren-wu-yi-bu-cun-zai')
          });
        }
      });
    },
    handleCancel() {
      this.showAuditDetail = false;
    },
    formatAuditContent(data) {
      return JSON.parse(`[${data.split('] ')[1]}`);
    }
  }
};
</script>

<style scoped lang="less">
.operation-log {
  height: 100%;
  display: flex;
  flex-direction: column;

  .uid {
    display: flex;
    cursor: pointer;
    .copy {
      display: none;
    }

    &:hover {
      .copy {
        display: block;
      }
    }
  }
}
</style>
