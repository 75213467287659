import { EDITIONS, VERIFY_TYPE } from '@/const/ccIndex';

const RULE_KIND_CONF_MAP = {
  QUERY: 'queryConf',
  SENSITIVE: 'senConf'
};

export default {
  userInfo: (state) => state.userInfo,
  isDesktop: (state) => !!state.dmGlobalSetting.personal,
  isAsia: (state) => state.globalSetting.deploySite === 'asia_pacific',
  upgradeSidecar: (state) => state.ccGlobalSetting.productConsolePackageMode === 'TGZ',
  blackUri: (state) => state.blackUri,
  globalConfig: (state) => state.globalConfig,
  isProductTrail: (state) => state.ccGlobalSetting.productAuthType === EDITIONS.COMMUNITY_VERSION,
  isExperienceVersion: (state) => state.ccGlobalSetting.productAuthType === EDITIONS.EXPERIENCE_VERSION,
  verifyType: (state) => (state.globalSetting.deploySite === 'asia_pacific' ? VERIFY_TYPE.SMS_VERIFY_CODE : VERIFY_TYPE.EMAIL_VERIFY_CODE),
  productClusterList: (state) => state.productClusterList,
  includesCC: (state) => state.globalSetting.features && state.globalSetting.features.PRODUCT_CLOUD_CANAL,
  includesDM: (state) => state.globalSetting.features && state.globalSetting.features.PRODUCT_CLOUD_DM,
  buildVersion: (state) => state.dmGlobalSetting.buildVersion,
  getNodeType: (state) => (type, deep) => state.globalDsSetting[type].categories.levels[deep],
  getLevels: (state) => (type) => {
    if (state.globalDsSetting[type]) {
      return state.globalDsSetting[type]?.categories.levels;
    }
    return [];
  },
  getLeafGroup: (state) => (type) => state.globalDsSetting[type]?.categories?.leafGroup,
  getQuickQuery: (state) => (type) => {
    if (state.globalDsSetting && state.globalDsSetting[type] && state.globalDsSetting[type].constant) {
      return {
        quickQuery: state.globalDsSetting[type].constant.quickQuery,
        quickQueryColumn: state.globalDsSetting[type].constant.quickQueryColumn,
        quickQueryMap: state.globalDsSetting[type].constant.quickQueryMap
      };
    }
  },
  getBrowserMenus: (state) => (dsType = null, tarType = null) => {
    if (dsType && tarType && state.globalDsSetting && state.globalDsSetting[dsType] && state.globalDsSetting[dsType].menus && state.globalDsSetting[dsType].menus[tarType]) {
      return state.globalDsSetting[dsType].menus[tarType];
    } else {
      return state.dmGlobalSetting.menus[tarType];
    }
  },
  getMenus: (state) => (menuType, dsType = null) => (dsType
    ? state.globalDsSetting && state.globalDsSetting[dsType] && state.globalDsSetting[dsType].menus && state.globalDsSetting[dsType].menus[menuType]
    : state.dmGlobalSetting.menus[menuType]),
  getQualifier: (state) => (dsType) => ({
    left: state.globalDsSetting[dsType].constant.leftQualifier || '"',
    right: state.globalDsSetting[dsType].constant.rightQualifier || '"'
  }),
  genQualifierText: (state) => (dsType, text) => `${state.globalDsSetting[dsType].constant.leftQualifier}${text}${state.globalDsSetting[dsType].constant.rightQualifier}`,
  removeQualifierText: (state) => (dsType, text) => {
    const { leftQualifier, rightQualifier } = state.globalDsSetting[dsType].constant;
    text = text.replace(leftQualifier, '');
    text = text.replace(rightQualifier, '');
    return text;
  },
  targetDsList: (state) => (dsType) => state.globalDsSetting[dsType].targetDsList,
  ddlList: (state) => (dsType) => state.globalDsSetting[dsType].ddlList,
  supportRdbTx: (state) => (dsType) => state.globalDsSetting[dsType]?.features?.FUN_SUPPORT_RDB_TX,
  isolations: (state) => (dsType) => {
    if (state.globalDsSetting[dsType]) {
      return state.globalDsSetting[dsType].isolations;
    }
  },
  getEditor: (state) => (id) => state.editorSet[id],
  getTargetType: (state) => (targetType) => state.ruleSetting.queryConf.targets.find((target) => target.name === targetType),
  getSenMode: (state) => (senMode) => state.ruleSetting.senConf.senMode.find((sen) => sen.name === senMode),
  getMatchMode: (state) => (ruleKind, matchMode) => state.ruleSetting[RULE_KIND_CONF_MAP[ruleKind]].matchMode.find((match) => match.name === matchMode),
  getScopeByMatchMode: (state) => (ruleKind, matchMode, rangeType) => state.ruleSetting[RULE_KIND_CONF_MAP[ruleKind]].scopeByMatchMode[matchMode].find((scope) => scope.name === rangeType),
  getMatchModeList: (state) => (ruleKind) => state.ruleSetting[RULE_KIND_CONF_MAP[ruleKind]].matchMode,
  getScopeListByMatchMode: (state) => (ruleKind, matchMode) => {
    if (!ruleKind || !matchMode) {
      return [];
    }
    return state.ruleSetting[RULE_KIND_CONF_MAP[ruleKind]].scopeByMatchMode[matchMode];
  },
  isInternalUser: (state) => state.userInfo.accountType === 'PRIMARY_ACCOUNT' || (state.userInfo.accountType !== 'PRIMARY_ACCOUNT' && state.userInfo.bindType === 'INTERNAL')
};
