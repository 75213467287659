<!--优化方案，为减少工作量-->
<!--以后添加icon的名字改成 云厂商-数据源类型格式，例如self-MySQL, ali-MySQL, aws-MySQL-->

<template>
  <div style="display: inline-block" class="datasource-icon">
    <svg class="icon" aria-hidden="true"
         v-if="type==='DocumentDB'">
      <use xlink:href="#icon-documentDB"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='ObForOracle'">
      <use xlink:href="#icon-OceanBase-oracle"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='Dameng'">
      <use xlink:href="#icon-dameng"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='Hana'">
      <use xlink:href="#icon-Hana"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='Redshift'">
      <use xlink:href="#icon-redshift"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='MariaDB'">
      <use xlink:href="#icon-MariaDB"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='GaussDBForOpenGauss'&&instanceType!=='ALIBABA_CLOUD_HOSTED'">
      <use xlink:href="#icon-gaussdb-opengauss"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='GaussDBForMySQL'&&instanceType!=='ALIBABA_CLOUD_HOSTED'">
      <use xlink:href="#icon-gaussdb-for-mysql"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='SelectDB'&&instanceType!=='ALIBABA_CLOUD_HOSTED'">
      <use xlink:href="#icon-selectdb"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='Db2'&&instanceType!=='ALIBABA_CLOUD_HOSTED'">
      <use xlink:href="#icon-db2"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='Tunnel'&&instanceType!=='ALIBABA_CLOUD_HOSTED'">
      <use xlink:href="#icon-logoCG"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='Doris'&&instanceType!=='ALIBABA_CLOUD_HOSTED'">
      <use xlink:href="#icon-doris"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='OceanBase'&&instanceType!=='ALIBABA_CLOUD_HOSTED'">
      <use xlink:href="#icon-oceanbase"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='StarRocks'&&instanceType!=='ALIBABA_CLOUD_HOSTED'">
      <use xlink:href="#icon-starrocks"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="(type==='MySQL'&&instanceType!=='ALIBABA_CLOUD_HOSTED')">
      <use xlink:href="#icon-MySQL"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type === 'AuroraMySQL'">
      <use xlink:href="#icon-aurora_icon"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="(type==='MySQL'&&instanceType==='ALIBABA_CLOUD_HOSTED')||type==='RdsForMySQL'">
      <use xlink:href="#icon-rds_mysql"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="(type==='PostgreSQL'&&instanceType!=='ALIBABA_CLOUD_HOSTED')">
      <use xlink:href="#icon-postgresl"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type === 'AuroraPostgreSQL'">
      <use xlink:href="#icon-aurora_icon"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="(type==='PostgreSQL'&&instanceType==='ALIBABA_CLOUD_HOSTED')||type==='RdsForPostgreSQL'">
      <use xlink:href="#icon-rds_postgresql"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="type==='Greenplum'&&instanceType!=='ALIBABA_CLOUD_HOSTED'">
      <use xlink:href="#icon--Greenplum"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="(type==='Greenplum'&&instanceType==='ALIBABA_CLOUD_HOSTED')||type==='AdbForPostgreSQL'">
      <use xlink:href="#icon-ic_datasource_analyticdb"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='Hive'">
      <use xlink:href="#icon-hive"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='TiDB'">
      <use xlink:href="#icon-tidbConnection"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='Kafka'">
      <use xlink:href="#icon-Kafka"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='ElasticSearch'">
      <use xlink:href="#icon-elastic"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='RocketMQ'">
      <use xlink:href="#icon-RocketMQ"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='RabbitMQ'">
      <use xlink:href="#icon-rabbitmq"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='MqForKafka'">
      <use xlink:href="#icon-alikafkaxiaoxiduilie"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='MqForRocketMQ'">
      <use xlink:href="#icon-RocketMQConsumer_small"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='MqForRabbitMQ'">
      <use xlink:href="#icon-AMQP"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='Oracle'">
      <use xlink:href="#icon-bianzu"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='DRDS'">
      <use xlink:href="#icon-drdsfenbushiguanxixingshujukufuwu"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='PolarDbX'">
      <use xlink:href="#icon-drdsfenbushiguanxixingshujukufuwu"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='AdbForMySQL'">
      <use xlink:href="#icon-AnalyticDB"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='ClickHouse'">
      <use xlink:href="#icon-clickHouse"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='PolarDbMySQL'">
      <use xlink:href="#icon-polardbyunshujukuPolarDB"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='Redis'">
      <use xlink:href="#icon-redis"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='MongoDB'">
      <use xlink:href="#icon-ziyuan"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='Kudu'">
      <use xlink:href="#icon-KUDU"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="(type==='SQLServer'&&instanceType==='ALIBABA_CLOUD_HOSTED')">
      <use xlink:href="#icon-SQLServer"></use>
    </svg>
    <svg class="icon" aria-hidden="true"
         v-if="(type==='SQLServer'&&instanceType!=='ALIBABA_CLOUD_HOSTED')">
      <use xlink:href="#icon-sqlserver-copy"></use>
    </svg>
    <svg class="icon" aria-hidden="true" v-if="type==='Hudi'">
      <use xlink:href="#icon-hudi"></use>
    </svg>
    <img style="width: 16px;vertical-align: middle" src="../../assets/datasource/automq.svg" alt="AutoMQ" v-if="type === 'AutoMQ'">
    </div>
</template>

<script>
export default {
  props: {
    type: String,
    instanceType: String
  }
};
</script>
<style lang="less" scoped>
.datasource-icon .icon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
</style>
