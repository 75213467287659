export const checkStatus = (res) => {
  console.log(res, res.data.message || res.data || '');
  const { status } = res;

  if (status === 200) {
    return res.data;
  }

  const msg = res.data.message || res.data || '';

  return {
    status,
    msg
  };
};
