<template>
  <div class="table-filter">
    <div class="table-filter-nav" :style="`height:${tableHeight+120}px;overflow:hidden;`">
      <div :style="`height:${tableHeight+75}px;overflow:auto`" ref="tableFilterNav">
        <div @click="handleCheckTableList(db,index)"
             :class="`table-filter-nav-item ${index===selectedIndex?'table-filter-nav-item-selected':''}`"
             v-for="(db,index) in computedDbMap" :key="index">
          <DbMappingInTableFilter
            v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)===-1&&DataSourceGroup.mq.indexOf(taskInfo.sourceType)===-1&&
            DataSourceGroup.es.indexOf(taskInfo.sinkType)===-1&&DataSourceGroup.redis.indexOf(taskInfo.sinkType)===-1&&DataSourceGroup.kudu.indexOf(taskInfo.sinkType)===-1"
            :sourceType="taskInfo.sourceType"
            :sinkType="taskInfo.sinkType" :taskInfo="taskInfo"
            :selectedTables="selectedTables" :db="db"
            :getSelectedInfo="getSelectedInfo"></DbMappingInTableFilter>
          <DbItem :type="DataSourceGroup.mq.includes(taskInfo.sourceType)?'source':'target'"
                  v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1||DataSourceGroup.mq.indexOf(taskInfo.sourceType)>-1||
            DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1||DataSourceGroup.redis.indexOf(taskInfo.sinkType)>-1||DataSourceGroup.kudu.indexOf(taskInfo.sinkType)>-1"
                  :selectedTables="selectedTables" :db="db"
                  :getSelectedInfo="getSelectedInfo" :task-info="taskInfo"></DbItem>
          <i class="iconfont icondel" @click.stop="handleDeleteDb(index, db)" v-if="taskInfo.processType==='edit'"></i>
        </div>
        <div class="new-add-db-item" v-if="showAddMap">
          <div v-if="DataSourceGroup.mq.indexOf(taskInfo.sourceType) === -1">
            <div v-if="DataSourceGroup.pg.indexOf(taskInfo.sourceType)===-1">
              <Select style="width: 252px" v-model="newAddMap.sourceDb" :placeholder="$t('qing-xuan-ze-yuan-shu-ju-ku')" transfer
                      filterable @on-change="handleDbChange('source', $event)">
                <Option :disabled="hasSelectedTheDb(sourceDb.dbName, 'source')"
                        v-for="(sourceDb,index1) in taskInfo.sourceDbList"
                        :value="sourceDb.dbName" :key="index1+sourceDb">{{ sourceDb.dbName }}
                </Option>
              </Select>
            </div>
            <div v-if="DataSourceGroup.pg.indexOf(taskInfo.sourceType)>-1">
              <Select style="width: 156px;margin-right: 6px" v-model="newAddMap.sourceDb" :placeholder="$t('qing-xuan-ze-yuan-shu-ju-ku')" transfer
                      filterable>
                <Option :disabled="hasSelectedTheDb(sourceDb.dbName, 'source')"
                        v-for="(sourceDb,index1) in taskInfo.sourceDbList"
                        :value="sourceDb.dbName" :key="index1+sourceDb">{{ sourceDb.dbName }}
                </Option>
              </Select>
              <Select style="width: 90px" v-model="newAddMap.sourceSchema" placeholder="schema" transfer
                      filterable>
                <Option v-for="(schema,index1) in getSchemaList(taskInfo.sourceDbList,db.sourceDb)" :value="schema"
                        :key="index1+schema">{{ schema }}
                </Option>
              </Select>
            </div>
          </div>
          <div v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType) === -1">
            <div v-if="DataSourceGroup.pg.indexOf(taskInfo.sinkType)===-1">
              <Select style="width: 252px;margin-top: 10px"
                      v-model="newAddMap.sinkDb"
                      :placeholder="$t('qing-xuan-ze-mu-biao-shu-ju-ku')"
                      :class="!containsDb(taskInfo.sinkDbList,newAddMap.sinkDb)?'db-to-create':''"
                      transfer
                      filterable>
                <Option v-for="(sinkDb,index1) in taskInfo.sinkDbList"
                        :value="sinkDb.dbName" :key="index1+sinkDb">{{ sinkDb.dbName }}
                </Option>
                <!--                <Option v-if="showNeedAutoCreatedDb"-->
                <!--                        :value="taskInfo.targetCaseSensitive==='false'?newAddMap.sourceDb.toLowerCase():newAddMap.sourceDb"-->
                <!--                        :key="999">-->
                <!--                  {{ $t('taskinfotargetcasesensitive-false-newaddmapsourcedbtolowercase-newaddmapsourcedb-dai-chuang-jian', [taskInfo.targetCaseSensitive === 'false' ? newAddMap.sourceDb.toLowerCase() : newAddMap.sourceDb]) }}-->
                <!--                </Option>-->
              </Select>
            </div>
            <div v-if="DataSourceGroup.pg.indexOf(taskInfo.sinkType)>-1">
              <Select style="width: 156px;margin-top: 10px;margin-right: 6px" v-model="newAddMap.sinkDb" :placeholder="$t('qing-xuan-ze-mu-biao-shu-ju-ku')"
                      transfer
                      filterable
                      :class="!containsDb(taskInfo.sinkDbList,newAddMap.sinkDb)?'db-to-create':''"
              >
                <Option v-for="(sinkDb,index1) in taskInfo.sinkDbList"
                        :value="sinkDb.dbName" :key="index1+sinkDb">{{ sinkDb.dbName }}
                </Option>
                <!--                <Option v-if="showNeedAutoCreatedDb"-->
                <!--                        :value="taskInfo.targetCaseSensitive==='false'?newAddMap.sourceDb.toLowerCase():newAddMap.sourceDb"-->
                <!--                        :key="999">-->
                <!--                  {{ $t('taskinfotargetcasesensitive-false-newaddmapsourcedbtolowercase-newaddmapsourcedb-dai-chuang-jian-0', [taskInfo.targetCaseSensitive === 'false' ? newAddMap.sourceDb.toLowerCase() : newAddMap.sourceDb]) }}-->
                <!--                </Option>-->
              </Select>
              <Select style="width: 90px;margin-top: 10px" v-model="newAddMap.targetSchema" placeholder="schema"
                      transfer
                      filterable
                      :class="getSchemaList(taskInfo.sinkDbList,newAddMap.sinkDb)&&getSchemaList(taskInfo.sinkDbList,newAddMap.sinkDb).indexOf(newAddMap.targetSchema)===-1?'db-to-create':''"
              >
                <Option v-for="(schema,index1) in getSchemaList(taskInfo.sinkDbList,newAddMap.sinkDb)" :value="schema"
                        :key="index1+schema">{{ schema }}
                </Option>
              </Select>
            </div>
          </div>
          <div style="margin-top: 10px">
            <Button style="margin-right: 10px" type="primary" @click="handleConfirmNewDb">{{ $t('que-ding') }}</Button>
            <Button @click="handleCancelAddDb">{{ $t('qu-xiao') }}</Button>
          </div>
        </div>
      </div>
      <div class="add-db-item" v-if="type==='edit'&&!isOracle(taskInfo.sinkType)&&!isHasSchema(taskInfo.sourceType)&&(!isMQ(taskInfo.sourceType))">
        <p><a @click="handleAddNewDb"><i class="iconfont iconadd"></i>{{ $t('zeng-jia-ku') }}</a></p>
      </div>
    </div>
    <div class="table-filter-container">
      <div>
        <div
          v-if="!DataSourceGroup.mq.includes(taskInfo.sourceType)&&filterTable[db.sourceDb]||DataSourceGroup.mq.includes(taskInfo.sourceType)&&filterTable[db.sinkDb]">
          <FilterHeader :filterTable="filterTable" :db="db"
                        :handleChangeFilterType="handleChangeFilterType"
                        :handleTableFilter="handleTableFilter" :taskInfo="taskInfo"
                        :handleSetPartitions="handleSetPartitions"
                        :handle-batch-action-filter="handleBatchActionFilter" :handleSetShards="handleSetShards"
                        :filterList="filterList" :handleDeleteFilter="handleDeleteFilter"
                        :handleClearFilter="handleClearFilter" :migrationType="migrationType"
                        :handleChangeMigrationType="handleChangeMigrationType" :loading="loading"
                        :handleDefaultTopic="handleDefaultTopic"
                        :handleShowMappingRule="handleShowMappingRule"
                        :handleSetTopic="handleSetTopic" :tableDisabled="tableDisabled"
                        :theMappingRule="taskInfo.tableMappingRule" :handleShowSetTable="handleShowSetTable"
                        ref="filterHeader"></FilterHeader>
        </div>
        <FilterBody :tableHeight="tableHeight" :taskInfo="taskInfo" :db="db" :loading="loading"
                    :getSelectedInfo="getSelectedInfo"
                    :showTableList="showTableList" :handleSelect="handleSelect"
                    :selectedIndex="selectedIndex" :handleSelectCancel="handleSelectCancel"
                    :handleSelectAll="handleSelectAll"
                    :handleSelectAllCancel="handleSelectAllCancel"
                    :handleActionChange="handleActionChange" :selectedTables="selectedTables"
                    :handleSelectChange="handleSelectChange" :topicName="topicName"
                    :handleChangeShards="handleChangeShards" :pageData="pageData"
                    :handlePageChange="handlePageChange"
                    :handlePageSizeChange="handlePageSizeChange"
                    :handleChangeReplication="handleChangeReplication"
                    :containsTable="containsTable" :handleChooseIndex="handleChooseIndex"
                    :migrationType="migrationType" :handleFocus="handleFocus"
                    :remoteMethod="remoteMethod" :tableDisabled="tableDisabled"
                    :handleBlur="handleBlur"
                    :theMappingIndex="taskInfo.tableMappingIndex"
                    :updateShowTableList="updateShowTableList" :hideSelectAll="hideSelectAll"
                    :theMappingRule="taskInfo.tableMappingRule" ref="tableFilterBody"></FilterBody>
      </div>
    </div>
    <Modal
      v-model="showSetTopic"
      :title="setDefaultValue?$t('she-zhi-mo-ren-topic'):DataSourceGroup.mq.includes(taskInfo.sinkType)?$t('pi-liang-she-zhi-topic-ming-cheng'):$t('pi-liang-she-zhi-mu-biao-biao')"
      :closable="false"
      footer-hide
    >
      <div>
        <Form :label-width="80">
          <FormItem :label="DataSourceGroup.mq.includes(taskInfo.sinkType)?$t('topic-ming-cheng'):$t('mu-biao-biao')">
            <Select v-model="currentTopicName"
                    :placeholder="DataSourceGroup.mq.includes(taskInfo.sinkType)?$t('qing-xuan-ze-topic-ming-cheng'):$t('qing-xuan-ze-mu-biao-biao')"
                    filterable>
              <Option
                v-for="(topic,index) of taskInfo.sinkTableList[taskInfo.dbMap[selectedIndex].sourceDb]"
                :value="topic.tableName" :key="index">{{ topic.tableName }}
              </Option>
            </Select>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmSetTopic">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showMappingRule"
      :title="$t('mu-biao-ying-she-gui-ze')"
      :closable="false"
      footer-hide
    >
      <div>
        <Form :label-width="80">
          <FormItem :label="$t('ying-she-gui-ze')">
            <Select v-model="taskInfo.tableMappingIndex" :placeholder="$t('qing-xuan-ze-ying-she-gui-ze')" filterable>
              <Option v-for="(rule,index) of taskInfo.tableMappingRule"
                      :value="index" :key="index">{{ rule.i18nRuleName }}
              </Option>
            </Select>
          </FormItem>
          <!--          <FormItem :label="DataSourceGroup.mq.includes(taskInfo.sinkType)?'Topic名称':'目标表'">-->
          <!--            <Select v-model="currentTopicName" :placeholder="DataSourceGroup.mq.includes(taskInfo.sinkType)?'请选择Topic名称':'请选择目标表'" filterable>-->
          <!--              <Option v-for="(topic,index) of store.state.sinkTableList[taskInfo.dbMap[selectedIndex].sourceDb]"-->
          <!--                      :value="topic.tableName" :key="index">{{topic.tableName}}</Option>-->
          <!--            </Select>-->
          <!--          </FormItem>-->
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmSetMappingRule">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showSetShards"
      :title="$t('pi-liang-she-zhi-fen-pian-shu')"
      :closable="false"
      footer-hide
    >
      <div>
        <Form :label-width="80" inline>
          <FormItem :label="$t('fen-pian-shu')">
            <Input style="width: 100px;margin-right: 60px" v-model="shardsCount" number type="number"></Input>
          </FormItem>
          <FormItem :label="$t('fen-pian-fu-ben-shu')">
            <Input style="width: 100px" v-model="shardsReplication" number type="number"></Input>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmSetShards">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showSetTable"
      :title="$t('pi-liang-she-zhi-mu-biao-biao-ming-cheng')"
      :closable="false"
      footer-hide
    >
      <div>
        <Form inline style="margin-top: 18px">
          <FormItem>
            <Select style="width: 160px" v-model="selectedSetTableMethod" :placeholder="$t('qing-xuan-ze-yuan-shu-ju-ku')" transfer>
              <Option v-for="(method) in Object.keys(setTableMethods)"
                      :value="method" :key="method">{{ setTableMethods[method] }}
              </Option>
            </Select>
          </FormItem>
          <FormItem>
            <Input v-if="selectedSetTableMethod !== 'suffix'" style="width: 140px;margin-right: 10px" v-model="setTablePrefixValue" :placeholder="$t('qing-shu-ru-qian-zhui')"></Input>
            <Input v-if="selectedSetTableMethod !== 'prefix'" style="width: 140px" v-model="setTableSuffixValue" :placeholder="$t('qing-shu-ru-hou-zhui')"></Input>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmSetTable">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showPreCheck"
      :closable="false"
      :footer-hide="true"
      :mask-closable="false"
      width="1000px"
      :title="$t('yu-jian-shi-bai')">
      <div>
        <div class="preCheck-title">
          <span class="preCheck-title-desc">{{ preCheckStatusDesc[preCheckStatus].desc }}</span>
          {{ $t('gong-jian-cha') }}<span class="preCheck-title-count">{{ preCheckItems.length }}</span>
          {{ $t('xiang-yi-jian-cha') }}<span class="preCheck-title-count">{{ preCheckItemsCheckedCount }}</span>{{ $t('xiang-shi-bai') }}
          <span class="preCheck-title-count">{{ preCheckItemsErrorCount }}</span>{{ $t('xiang') }}
        </div>
        <div style="max-height: 400px;overflow: auto">
          <Table stripe border size="small" :columns="preCheckColumns" :data="preCheckItems">
            <template slot-scope="{ row }" slot="preCheckResult">
              <div style="font-size: 18px">
                <Icon type="md-checkmark" style="color: #19be6b" v-if="row.success&&row.checked"/>
                <Icon type="md-close" style="color: #ed4014" v-if="!row.success&&row.checked"/>
                <span v-if="!row.checked" style="font-size: 12px;color: #808695;">{{ $t('dai-jian-cha') }}</span>
              </div>
            </template>
          </Table>
        </div>
        <p style="margin: 20px 0;text-align: center">
          <pd-button style="width: 120px;height: 32px;line-height: 30px" type="default"
                     @click="handleHidePrecheck(false)">{{ $t('guan-bi') }}
          </pd-button>
        </p>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  batchListColumns, getDbList, getTableList, queryIndex
} from '@/services/cc/api/datasource';
import _ from 'lodash';
import { noPkUkSyncUpdateDelete, tableMappingRule, editJobInitialSyncSwitch } from '@/services/cc/api/constant';
import createJobMixin from '@/components/function/mixin/createJobMixin';
import {
  isES,
  isHasSchema,
  isKafka,
  isMongoDB, isMQ, isMySQL, isOracle, isPG, isPolar, isRedis, isTunnel, isHudi
} from '@/utils';
import { queryJobById, queryJobSchemaById, tableCheck } from '@/services/cc/api/job';
import Mapping from '@/views/util';
import store from '@/store';
import DataSourceGroup from '@/views/dataSourceGroup';
import { mapActions, mapState } from 'vuex';
import DbMappingInTableFilter from './DbMappingInTableFilter';
import DbItem from './DbItem';
import FilterHeader from './tableFilter/FilterHeader';
import FilterBody from './tableFilter/FilterBody';
import { getTargetName } from './util';

export default {
  name: 'TableFilter',
  mixins: [createJobMixin],
  props: {
    currentStep: Number,
    type: {
      default: 'create'
    },
    taskInfo: Object,
    updateStep: Function,
    updateLoading: Function,
    updateNextDisabled: Function,
    defaultSelected: Boolean
  },
  components: {
    DbMappingInTableFilter,
    DbItem,
    FilterHeader,
    FilterBody
  },
  async created() {
    if (this.$route.name === 'taskEdit/process' && !this.taskInfo.firstEdit) {
      this.taskInfo.firstEdit = true;
      let jobData = {};
      const { jobId } = this.$route.query;
      const res = await queryJobById({ jobId });
      if (res.data.success) {
        jobData = res.data.data;
        this.jobData = jobData;
        this.taskInfo.sourceType = jobData.sourceDsVO.dataSourceType;
        this.taskInfo.sinkType = jobData.targetDsVO.dataSourceType;
        this.updateLoading(true);
        this.updateNextDisabled(true);
        this.taskInfo.dbMap.forEach((db) => {
          if (isES(this.taskInfo.sourceType)) {
            db.sourceDb = 'empty';
          }
          if (isES(this.taskInfo.sinkType)) {
            db.sinkDb = 'empty';
          }
        })
        const res2 = await queryJobSchemaById({
          jobId,
          srcDsType: jobData.sourceDsVO.dataSourceType,
          dstDsType: jobData.targetDsVO.dataSourceType
        });

        if (res2.data.success) {
          if (isES(jobData.sourceDsVO.dataSourceType)) {
            res2.data.data.sourceSchema = JSON.stringify([{
              db: 'empty',
              tables: JSON.parse(res2.data.data.sourceSchema)
            }])
          }
          this.taskInfo.schemaData = {
            ...res2.data.data,
            mappingConfig: JSON.parse(res2.data.data.mappingConfig),
            sourceSchema: JSON.parse(res2.data.data.sourceSchema),
            targetSchema: res2.data.data.targetSchema ? JSON.parse(res2.data.data.targetSchema) : null
          };
          this.taskInfo.mappingDef = JSON.parse(res2.data.data.mappingConfig);
          jobData.schemaData = res2.data.data;
          await this.getSourceAndSinkDbList(jobData);
          this.taskInfo.schemaWhiteListLevel = res2.data.data.schemaWhiteListLevel;
          this.taskInfo.advancedSetting.srcSchemaLessFormat = res2.data.data.srcSchemaLessFormat;
          this.taskInfo.advancedSetting.dstSchemaLessFormat = res2.data.data.dstSchemaLessFormat;
          console.log('this.taskInfo.advancedSetting', this.taskInfo.advancedSetting);
        }
      }
      this.taskInfo.jobData = jobData;

      // const jobData = store.state.jobData;
      this.taskInfo.initialSync = jobData.initialSync;
      this.taskInfo.ddl = JSON.stringify(jobData.filterDdl);
      this.taskInfo.clusterId = jobData.clusterId;
      this.taskInfo.sourceDataSourceIdd = jobData.sourceDsVO.id;
      this.taskInfo.sourceInstanceType = jobData.sourceDsVO.deployType;
      this.taskInfo.sourceInstance = jobData.sourceDsVO.host;
      this.taskInfo.sourceHostType = jobData.sourceDsHostType;
      this.taskInfo.sourceDataSourceId = jobData.sourceDsVO.id;
      this.taskInfo.sourcePrivateHost = jobData.sourceDsVO.privateHost;
      this.taskInfo.sourceInstanceId = jobData.sourceDsVO.instanceId;
      this.taskInfo.sourcePublicHost = jobData.sourceDsVO.publicHost;
      this.taskInfo.sourceInstanceValue = `${this.taskInfo.sourcePrivateHost}|${this.taskInfo.sourceDataSourceId}|${this.taskInfo.sourcePrivateHost}|${this.taskInfo.sourcePublicHost}|${
        this.taskInfo.sourceInstanceId}`;
      this.taskInfo.targetDataSourceId = jobData.targetDsVO.id;
      this.taskInfo.sinkInstanceType = jobData.targetDsVO.deployType;
      this.taskInfo.sinkInstance = jobData.targetDsVO.host;
      this.taskInfo.targetHostType = jobData.targetDsHostType;
      this.taskInfo.targetDataSourceId = jobData.targetDsVO.id;
      this.taskInfo.sinkPrivateHost = jobData.targetDsVO.privateHost;
      this.taskInfo.sinkInstanceId = jobData.targetDsVO.instanceId;
      this.taskInfo.sinkPublicHost = jobData.targetDsVO.publicHost;
      this.taskInfo.sinkInstanceValue = `${this.taskInfo.sinkPrivateHost}|${this.taskInfo.targetDataSourceId}|${this.taskInfo.sinkPrivateHost}|${this.taskInfo.sinkPublicHost}|${
        this.taskInfo.sinkInstanceId}`;
      this.taskInfo.type = jobData.dataJobType;
      this.taskInfo.desc = jobData.dataJobDesc;

      if (jobData.processorConfigListJson) {
        const processList = JSON.parse(jobData.processorConfigListJson);
        processList.forEach((process) => {
          if (process.processorConfigType === 'FIELD_MAKER_PROCESSOR') {
            if (process.allProcessorContextJson) {
              this.taskInfo.virtualColumns = [];
              this.taskInfo.customVirtualColumns = [];
              const fieldProcessList = JSON.parse(process.allProcessorContextJson);
              fieldProcessList.forEach((fieldProcess) => {
                this.taskInfo.virtualColumns.push(fieldProcess);
                this.taskInfo.virtualColumnData[fieldProcess.fieldPathName] = [];
                if (fieldProcess.customFieldList) {
                  fieldProcess.customFieldList.forEach((item) => {
                    const virtualColumn = {};
                    virtualColumn.customName = item.fieldName;
                    virtualColumn.targetVirtualName = item.fieldName;
                    virtualColumn.columnLength = item.length;
                    virtualColumn.columnValue = item.fieldValue;
                    virtualColumn.genRule = item.genRule;
                    virtualColumn.isCustom = item.needAutoCreate;
                    virtualColumn.sqlType = item.sqlType;
                    virtualColumn.targetVirtualType = item.typeName;
                    this.taskInfo.virtualColumnData[fieldProcess.fieldPathName].push(virtualColumn);
                  });
                }
              });
            }
          } else if (process.processorConfigType === 'CUSTOM_PKG_PROCESSOR') {
            this.taskInfo.customPkgProcess = process;
          }
        });
        this.taskInfo.virtualColumns.forEach((virtual) => {
          virtual.hasInJob = true;
        });
      }

      this.taskInfo.targetCaseSensitive = JSON.stringify(jobData.dstDsCaseSensitive);
      // rabbitmq
      this.taskInfo.dstRabbitExchange = jobData.targetDsVO.rabbitMqExchange;
      this.taskInfo.dstRabbitMqVhost = jobData.targetDsVO.rabbitMqVhost;
      // kafka
      this.taskInfo.dstMqDefaultTopic = jobData.targetDsVO.mqDefaultTopic;
      this.taskInfo.dstMqDefaultTopicPartitions = jobData.targetDsVO.mqDefaultTopicPartitions;
      this.taskInfo.reSchemaMigration = JSON.stringify(jobData.reSchemaMigration);
      if (this.taskInfo.schemaWhiteListLevel === 'DB') {
        this.editJobInitialSyncSwitch();
      }
    }
    if (this.taskInfo.schemaWhiteListLevel === 'DB') {
      this.tableDisabled = true;
    }
    this.store = store;
    const dbList = [];

    // if (this.taskInfo.sinkType === 'Oracle') {
    //   this.taskInfo.commonRule = 'UpperCase';
    // }
    this.taskInfo.dbMap.forEach((item) => {
      item.needAutoCreated = !(DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType) > -1);
      this.taskInfo.sinkDbList.forEach((db) => {
        if (!isHudi(this.taskInfo.sinkType)) {
          if (item.sinkDb === db.dbName) {
            item.needAutoCreated = false;
          }
        } else {
          if (db.schemas.includes(item.sourceDb)) {
            item.needAutoCreated = false;
          }
        }
      });
      dbList.push({
        sourceDb: item.sourceDb,
        sinkDb: item.sinkDb
      });
    });
    this.db = this.taskInfo.dbMap[0];
    this.selectedIndex = 0;
    if (!this.taskInfo.firstToTableFilter) {
      console.log(1);
      this.getMappingRule(this.init);

      this.taskInfo.firstToTableFilter = true;
      this.taskInfo.compareSelectedDbs = dbList;
    } else if (_.isEqual(this.taskInfo.compareSelectedDbs, dbList)) {
      console.log(2);
      this.getMappingRule();

      this.selectedTables = this.taskInfo.selectedTables;
      this.taskInfo.dbMap.forEach((item) => {
        let db = item.sourceDb;

        if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
          db = item.sinkDb;
        }

        // this.filterTable[db] = {};
        // this.filterTable[db].tableFilterName = '';
        // this.filterTable[db].tableFilterCreate = ['autoCreated', 'noCreate'];
        // this.filterTable[db].tableFilterAction = {
        //   INSERT: true,
        //   UPDATE: true,
        //   DELETE: true
        // };
        // this.filterTable[db].tableFilterType = '';
        // this.filterTable[db].tableFilterPk = ['hasPk', 'noPk'];
        // this.filterTable[db].tableFilterColumn = '';
        this.filterTable[db] = this.taskInfo.filterTable[db];
        this.showList[db] = this.selectedTables[db];
        this.pageData[db] = {
          page: 1,
          size: 20,
          total: this.showList[db] ? this.showList[db].length : 0
        };
        this.showTableList[db] = this.showList[db]
          ? this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size,
            this.pageData[db].page * this.pageData[db].size) : [];
      });
      const that = this;

      setTimeout(() => {
        that.currentNum = '0';
      });
      this.forceUpdateView();
      this.taskInfo.compareSelectedDbs = dbList;
      this.updateLoading(false);
      this.updateNextDisabled(false);
    } else {
      console.log(3);
      this.getMappingRule(this.init);
      this.taskInfo.compareSelectedDbs = dbList;
    }
    noPkUkSyncUpdateDelete({
      srcDsType: this.taskInfo.sourceType,
      dstDsType: this.taskInfo.sinkType
    }).then((res) => {
      if (res.data.code === '1') {
        this.hasUpdateAndDelete = res.data.data;
      }
    });
  },
  mounted() {
    console.log('mounted');
    this.calcHeight();
  },
  watch: {
    // eslint-disable-next-line func-names
    'taskInfo.sinkDbList': function () {
      this.taskInfo.dbMap.forEach((item) => {
        item.needAutoCreated = !(DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType) > -1);
        this.taskInfo.sinkDbList.forEach((db) => {
          if (item.sinkDb === db.dbName) {
            item.needAutoCreated = false;
          }
        });
      });
    }
  },
  data() {
    return {
      showPreCheck: false,
      showSetTable: false,
      preCheckStatusDesc: {
        processing: {
          desc: this.$t('yu-jian-zhong'),
          color: ''
        },
        success: {
          desc: this.$t('yu-jian-cheng-gong'),
          color: '#19be6b'
        },
        error: {
          desc: this.$t('yu-jian-shi-bai'),
          color: '#ed4014'
        }
      },
      preCheckItems: [],
      preCheckItemsMap: {},
      preCheckItemsSuccessCount: 0,
      preCheckItemsErrorCount: 0,
      preCheckItemsCheckedCount: 0,
      preCheckStatus: 'error',
      preCheckColumns: [
        {
          title: this.$t('lei-xing'),
          key: 'preCheckType',
          width: 220
        },
        {
          title: this.$t('biao-ti'),
          key: 'title'
        },
        {
          title: this.$t('cuo-shi'),
          key: 'passRequirement'
        },
        {
          title: this.$t('shi-bai-mu-biao'),
          render: (h, params) => {
            let value = '';

            if (params.row.contextDbName) {
              if (params.row.contextTableName) {
                if (params.row.contextColumnName) {
                  if (params.row.varActualValue) {
                    value = `${params.row.contextDbName}.${params.row.contextTableName}.${params.row.contextColumnName}:${params.row.varActualValue}`;
                  } else {
                    value = `${params.row.contextDbName}.${params.row.contextTableName}.${params.row.contextColumnName}`;
                  }
                } else {
                  value = `${params.row.contextDbName}.${params.row.contextTableName}`;
                }
              } else {
                value = `${params.row.contextDbName}`;
              }
            }
            return h('div', {}, params.row.checked && !params.row.success ? value : '');
          }
        },
        {
          title: this.$t('jian-cha-jie-guo'),
          slot: 'preCheckResult',
          width: 120
        }
      ],
      tableDisabled: false,
      reduceData: {},
      newAddMap: {},
      showAddMap: false,
      theMappingRule: [],
      theMappingIndex: 0,
      DataSourceGroup,
      remoteSearch: false,
      shardsCount: 5,
      shardsReplication: 1,
      searchLoading: false,
      migrationType: 'table',
      showSetTopic: false,
      showSetShards: false,
      currentTopicName: '',
      topicName: '',
      Mapping,
      existenceMap: {},
      db: {},
      selectedIndex: 0,
      maxSelectAllCount: 100000,
      filterList: {},
      loading: false,
      filterTable: {},
      showList: {},
      showTableList: {},
      noPkAndNotRds: [],
      pageData: {},
      showAll: true,
      index: 0,
      store: {},
      currentNum: '',
      sourceDb: '',
      sinkDb: '',
      currentDb: '',
      tableHeight: 600,
      tableData: [],
      sinkTableList: {
        db1: []
      },
      selectedTables: {},
      setDefaultValue: false,
      showMappingRule: false,
      promiseList: [],
      hasUpdateAndDelete: false,
      jobData: {},
      hideSelectAll: false,
      filterMode: 'fuzzy',
      selectedSetTableMethod: 'prefix',
      setTablePrefixValue: '',
      setTableSuffixValue: '',
      setTableMethods: {
        prefix: this.$t('jia-qian-zhui'),
        suffix: this.$t('jia-hou-zhui'),
        prefixAndSuffix: this.$t('jia-qian-zhui-he-hou-zhui')
      }
    };
  },
  computed: {
    getSinkList() {
      return (theDb) => {
        if (JSON.parse(this.taskInfo.reSchemaMigration)) {
          return [];
        }
        return this.taskInfo.sinkTableList[theDb];
      };
    },
    createReviseFromCheck() {
      return this.store.state.jobData && this.store.state.jobData.createReviseTask;
    },
    targetSchema() {
      return (isOracle(this.taskInfo.sourceType)
      && isPG(this.taskInfo.sinkType)
      && this.taskInfo.targetCaseSensitive === 'false' ? this.newAddMap.sourceSchema.toLowerCase().trim() : this.newAddMap.sourceSchema);
    },
    showNeedAutoCreatedSchema() {
      const { sinkInstanceType, sourceType, sinkDbList } = this.taskInfo;
      return this.newAddMap.sourceSchema
        && (sinkInstanceType !== 'ALIBABA_CLOUD_HOSTED'
          || (sinkInstanceType === 'ALIBABA_CLOUD_HOSTED' && (isMySQL(sourceType) || isPolar(sourceType) || isPG(sourceType))))
        && this.newAddMap.sourceDb && this.taskInfo.editType !== '2'
        && !this.containsSchema(sinkDbList, this.newAddMap.sinkDb, this.newAddMap.sourceSchema);
    },
    showNeedAutoCreatedDb() {
      const {
        sourceType, sinkType, sinkDbList, sinkInstanceType
      } = this.taskInfo;
      return (!isMongoDB(sourceType) || (isMongoDB(sourceType) && isMongoDB(sinkType)))
        && ((sinkInstanceType !== 'ALIBABA_CLOUD_HOSTED' && DataSourceGroup.oracle.indexOf(sinkType) === -1) || (sinkInstanceType === 'ALIBABA_CLOUD_HOSTED'
          && (DataSourceGroup.mysql.indexOf(sourceType) > -1 || DataSourceGroup.polar.indexOf(sourceType) > -1 || DataSourceGroup.pg.indexOf(sourceType) > -1)))
        && this.newAddMap.sourceDb && this.taskInfo.editType !== '2'
        && !this.containsDb(sinkDbList, this.newAddMap.sourceDb);
    },
    getSelectedInfo() {
      return (db) => {
        let n = 0;
        const tableMap = [];
        if (this.selectedTables[db] instanceof Array) {
          this.selectedTables[db].forEach((item) => {
            if (item.selected) {
              n++;
              tableMap.push({
                sourceTable: item.sourceTable,
                sinkTable: item.sinkTable
              });
            }
          });
        }
        return {
          selectedCount: n,
          tableMap
        };
      };
    },
    computedDbMap() {
      return this.taskInfo.dbMap.filter((t) => !t.delete);
    }
  },
  methods: {
    handleHidePrecheck() {
      this.showPreCheck = false;
    },
    isMQ,
    isHasSchema,
    isOracle,
    isMongoDB,
    handleDbChange(type, value) {
      console.log(type, value);
      const { sourceType, sinkType } = this.taskInfo;
      if (type === 'source') {
        if (this.taskInfo.targetCaseSensitive === 'false') {
          let hasSame = false;

          if (value) {
            if (DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) === -1 || DataSourceGroup.hasSchema.indexOf(this.taskInfo.sinkType) > -1) {
              this.taskInfo.sinkDbList.forEach((sinkDb) => {
                if (sinkDb.dbName.toUpperCase() === value.toUpperCase()) {
                  this.newAddMap.sinkDb = sinkDb.dbName.toLowerCase();

                  hasSame = true;
                }
              });
              if (!hasSame) {
                this.newAddMap.sinkDb = value.toLowerCase();
                if (DataSourceGroup.mongo.includes(this.taskInfo.sourceType)) {
                  this.newAddMap.sinkDb = '';
                }
              }
            }
          }
        } else {
          this.newAddMap.sinkDb = value;
          if (isMongoDB(sourceType) && !isMongoDB(sinkType)) {
            this.newAddMap.sinkDb = '';
            this.taskInfo.sinkDbList.forEach((sinkDb) => {
              if (sinkDb.dbName.toUpperCase() === value.toUpperCase()) {
                this.newAddMap.sinkDb = sinkDb.dbName.toLowerCase();
              }
            });
          }
        }
      } else {
        this.newAddMap.sinkDb = value;
      }
    },
    handleDeleteDb(index, db) {
      if (this.taskInfo.dbMap.length === 1) {
        this.$Modal.warning({
          title: this.$t('xiu-gai-ding-yue-ti-shi'),
          content: this.$t('qing-zhi-shao-bao-liu-yi-ge-ku')
        });
      } else {
        this.handleSelectAllCancel(index, db.sourceDb);
        this.taskInfo.dbMap.splice(index, 1);
        this.taskInfo.compareSelectedDbs.splice(index, 1);
        this.handleCheckTableList(this.taskInfo.dbMap[0], 0);
      }
    },
    newDbCheck() {
      try {
        let ifStandard = true;
        const sourceDbAndSchema = {};
        const targetDbAndSchema = {};

        this.taskInfo.dbMap.forEach((item) => {
          try {
            if (targetDbAndSchema[item.sinkDb] && targetDbAndSchema[item.sinkDb] !== item.targetSchema) {
              this.$Modal.warning({
                title: this.$t('chuang-jian-ren-wu-yi-chang-ti-shi'),
                content: this.$t('mu-qian-zan-bu-zhi-chi-duo-ku-qian-yi-xia-tong-ku-bu-tong-schema-de-qing-kuang-qing-chai-fen-cheng-duo-ge-ren-wu')
              });
              ifStandard = false;
            } else {
              targetDbAndSchema[item.sinkDb] = item.targetSchema;
            }
            if (sourceDbAndSchema[item.sourceDb] && sourceDbAndSchema[item.sourceDb] !== item.sourceSchema) {
              this.$Modal.warning({
                title: this.$t('chuang-jian-ren-wu-yi-chang-ti-shi'),
                content: this.$t('mu-qian-zan-bu-zhi-chi-duo-ku-qian-yi-xia-tong-ku-bu-tong-schema-de-qing-kuang-qing-chai-fen-cheng-duo-ge-ren-wu')
              });
              ifStandard = false;
            } else {
              sourceDbAndSchema[item.sourceDb] = item.sourceSchema;
            }
            if (DataSourceGroup.noDb.includes(this.taskInfo.sinkType)) {
              item.needAutoCreated = false;
            } else {
              item.needAutoCreated = !this.containsDb(this.taskInfo.sinkDbList, item.sinkDb);
            }
          } catch (e) {
            console.log('validate schema', e);
          }
        });

        return ifStandard;
      } catch (e) {
        console.log('catch error after validate', e);
      }
    },
    handleConfirmNewDb() {
      let error = false;
      if (DataSourceGroup.mq.includes(this.taskInfo.jobData.targetDsVO.dataSourceType)) {
        if (!this.newAddMap.sourceDb) {
          error = true;
        }
        if (DataSourceGroup.pg.indexOf(this.taskInfo.jobData.sourceDsVO.dataSourceType) > -1 && !this.newAddMap.sourceSchema) {
          error = true;
        }
      } else if (DataSourceGroup.mq.includes(this.taskInfo.jobData.sourceDsVO.dataSourceType)) {
        if (!this.newAddMap.sinkDb) {
          error = true;
        }
        if (DataSourceGroup.pg.indexOf(this.taskInfo.jobData.targetDsVO.dataSourceType) > -1 && !this.newAddMap.targetSchema) {
          error = true;
        }
      } else {
        if (!this.newAddMap.sourceDb || !this.newAddMap.sinkDb) {
          error = true;
        }

        if (DataSourceGroup.pg.indexOf(this.taskInfo.jobData.sourceDsVO.dataSourceType) > -1 && !this.newAddMap.sourceSchema) {
          error = true;
        }

        if (DataSourceGroup.pg.indexOf(this.taskInfo.jobData.targetDsVO.dataSourceType) > -1 && !this.newAddMap.targetSchema) {
          error = true;
        }
      }

      if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
        if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
          this.newAddMap.actions = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
        } else {
          this.newAddMap.actions = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
        }
      } else {
        this.newAddMap.actions = ['INSERT', 'UPDATE', 'DELETE'];
      }

      if (error) {
        this.$Modal.error({
          title: this.$t('tian-jia-ku'),
          content: this.$t('qing-tian-xie-wan-zheng-qie-zheng-que-de-yuan-ku-he-mu-biao-ku-xin-xi')
        });
        return;
      }
      if (DataSourceGroup.mq.includes(this.taskInfo.jobData.targetDsVO.dataSourceType)) {
        this.newAddMap.sinkDb = this.newAddMap.sourceDb;
      }

      this.selectedIndex = this.taskInfo.dbMap.length - 1;
      this.$nextTick(() => {
        const ifStandard = this.newDbCheck();
        if (ifStandard) {
          this._getTableList(this.newAddMap, this.taskInfo.dbMap.length - 1, 'new');
        }
      });
    },
    handleCancelAddDb() {
      this.showAddMap = false;
      this.newAddMap = {};
    },
    handleAddNewDb() {
      this.showAddMap = true;
      this.newAddMap = {
        sourceDb: '',
        sinkDb: '',
        targetSchema: 'public',
        sourceSchema: 'public',
        new: true
      };

      this.$nextTick(() => {
        this.$refs.tableFilterNav.scrollTo(0, this.taskInfo.dbMap.length * 100);
      });
    },
    async getSourceAndSinkDbList(jobData) {
      // console.log('getSourceAndSinkDbList');
      if (!DataSourceGroup.noDb.includes(jobData.sourceDsVO.dataSourceType)) {
        const sourceRes = await getDbList({
          host: jobData.sourceDsVO.host,
          privateHost: jobData.sourceDsVO.privateHost,
          publicHost: jobData.sourceDsVO.publicHost,
          hostType: jobData.sourceDsHostType,
          type: jobData.sourceDsVO.dataSourceType,
          dataSourceId: jobData.sourceDsVO.id,
          referenceDataJobId: jobData.dataJobId,
          referenceDsEndPointType: 'SOURCE'
        });
        if (sourceRes.data.success) {
          this.taskInfo.sourceDbList = sourceRes.data.data;
          this.parseExistSchemaData(jobData.schemaData, this.type);
        }
      }

      if (!DataSourceGroup.noDb.includes(jobData.targetDsVO.dataSourceType)) {
        const sinkRes = await getDbList({
          host: jobData.targetDsVO.host,
          privateHost: jobData.targetDsVO.privateHost,
          publicHost: jobData.targetDsVO.publicHost,
          hostType: jobData.targetDsHostType,
          type: jobData.targetDsVO.dataSourceType,
          dataSourceId: jobData.targetDsVO.id,
          referenceDataJobId: jobData.dataJobId,
          referenceDsEndPointType: 'TARGET'
        });

        if (sinkRes.data.success) {
          this.taskInfo.sinkDbList = sinkRes.data.data;
          this.parseExistSchemaData(jobData.schemaData, this.type);
        }
      }
    },
    calcHeight() {
      console.log('calc height');
      let totalHeight = window.innerHeight;

      if (totalHeight < 720) {
        totalHeight = 720;
      }
      // eslint-disable-next-line no-undef
      $('.table-filter-container')
        .css('height', `${totalHeight - 300}px`);
      this.tableHeight = totalHeight - 425;
      // if (this.taskInfo.sinkType === 'Kafka' || this.taskInfo.sinkType === 'RocketMQ') {
      //   this.tableHeight = totalHeight - 465;
      // }
    },
    _getTableList(item, index, type = 'init') {
      console.log('get table list');
      this.updateLoading(true);
      this.updateNextDisabled(true);

      if (!item.needAutoCreated && !item.schemaAutoCreate) {
        getTableList({
          host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
          privateHost: this.taskInfo.sinkPrivateHost,
          publicHost: this.taskInfo.sinkPublicHost,
          hostType: this.taskInfo.targetHostType,
          type: this.taskInfo.sinkType,
          userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sinkType) > -1 && this.taskInfo.sinkAccountRole ? `${this.taskInfo.sinkAccount} as SYSDBA` : this.taskInfo.sinkAccount,
          dbName: item.sinkDb,
          dataSourceId: this.taskInfo.targetDataSourceId,
          tableSchema: item.targetSchema,
          clusterId: this.taskInfo.clusterId,
          rabbitMqVhost: this.taskInfo.dstRabbitMqVhost,
          rabbitExchange: this.taskInfo.dstRabbitExchange,
          sysTriggerDataSchema: this.taskInfo.advancedSetting.sysTriggerDataSchema,
          sysTriggerDataTable: this.taskInfo.advancedSetting.sysTriggerDataTable
        })
          .then((response) => {
            this.taskInfo.sinkTableList[item.sourceDb] = [];
            if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
              this.taskInfo.sinkTableList[item.sinkDb] = response.data.data;
            } else {
              this.taskInfo.sinkTableList[item.sourceDb] = response.data.data;
            }
            getTableList({
              host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
              privateHost: this.taskInfo.sourcePrivateHost,
              publicHost: this.taskInfo.sourcePublicHost,
              hostType: this.taskInfo.sourceHostType,
              type: this.taskInfo.sourceType,
              dbName: item.sourceDb,
              dataSourceId: this.taskInfo.sourceDataSourceId,
              clusterId: this.taskInfo.clusterId,
              tableSchema: item.sourceSchema,
              specifiedUserPassword: this.taskInfo.sourceSpecifiedUserPassword,
              rabbitMqVhost: this.taskInfo.srcRabbitMqVhost,
              rabbitExchange: this.taskInfo.srcRabbitExchange,
              sysTriggerDataSchema: this.taskInfo.advancedSetting.sysTriggerDataSchema,
              sysTriggerDataTable: this.taskInfo.advancedSetting.sysTriggerDataTable
            })
              .then((res) => {
                this.loading = false;
                this.updateLoading(false);
                if (res.data.code === '1') {
                  this.updateNextDisabled(false);
                }
                try {
                  if (res.data.data.length < 1) {
                    this.$Modal.warning({
                      title: this.$t('ren-wu-chuang-jian-ti-shi'),
                      content: this.$t('dang-qian-yuan-ku-itemsourcedb-wei-kong-ku-biao-de-shu-liang-wei-0-bu-zhi-chi-qian-yi', [item.sourceDb])
                    });
                  } else {
                    if (type === 'new') {
                      this.taskInfo.dbMap.push(this.newAddMap);
                      this.taskInfo.compareSelectedDbs.push({
                        sourceDb: this.newAddMap.sourceDb,
                        sinkDb: this.newAddMap.sinkDb
                      });
                    }
                    const arr = [];
                    const
                      that = this;

                    let theDb = item.sourceDb;

                    if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
                      theDb = item.sinkDb;
                    }

                    res.data.data.forEach((table, i) => {
                      let checked = this.defaultSelected;
                      let selected = this.defaultSelected;
                      let disabled = this.tableDisabled;
                      let sinkTable = '';
                      let shards = 5;
                      let replication = 1;
                      const disabledMsgArr = [];

                      let action = [];

                      if (this.defaultSelected) {
                        if (!table.hasPk) {
                          if (this.hasUpdateAndDelete) {
                            if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
                              if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
                                action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
                              } else {
                                action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
                              }
                            } else {
                              action = ['INSERT', 'UPDATE', 'DELETE'];
                            }
                          } else {
                            if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
                              action = ['INSERT', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
                              if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
                                action = ['INSERT', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
                              } else {
                                action = ['INSERT', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
                              }
                            } else {
                              action = ['INSERT'];
                            }
                          }
                        } else {
                          if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
                            if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
                              action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
                            } else {
                              action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
                            }
                          } else {
                            action = ['INSERT', 'UPDATE', 'DELETE'];
                          }
                        }
                      }

                      if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
                        sinkTable = getTargetName(this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex], item.sinkDb, table, this.taskInfo);
                      } else {
                        sinkTable = getTargetName(this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex], item.sourceDb, table, this.taskInfo);
                        if (this.taskInfo.schemaWhiteListLevel === 'DB' && (this.taskInfo.dstMqDefaultTopic || this.taskInfo.schemaData && this.taskInfo.schemaData.defaultTopic)
                          && DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
                          sinkTable = this.taskInfo.dstMqDefaultTopic || this.taskInfo.schemaData.defaultTopic;
                        } else {
                          if (this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex].compareIgnoreCase) {
                            let hasSame = false;
                            this.taskInfo.sinkTableList[theDb].forEach((table1) => {
                              if (table1.tableName.toLowerCase() === sinkTable.toLowerCase()) {
                                sinkTable = table1.tableName;
                                hasSame = true;
                              }
                            });

                            if (!hasSame && ((isMongoDB(this.taskInfo.sourceType) && !isMongoDB(this.taskInfo.sinkType)))) {
                              checked = false;
                              sinkTable = '';
                              selected = false;
                              action = [];
                            }
                          } else {
                            let hasSame = false;
                            this.getSinkList(theDb).forEach((table1) => {
                              if (table1.tableName === sinkTable) {
                                sinkTable = table1.tableName;
                                hasSame = true;
                              }
                            });

                            if (!hasSame && isTunnel(this.taskInfo.sourceType)) {
                              sinkTable = '';
                            }

                            if (!hasSame && ((isMongoDB(this.taskInfo.sourceType) && !isMongoDB(this.taskInfo.sinkType)))) {
                              checked = false;
                              sinkTable = '';
                              selected = false;
                              action = [];
                            }
                          }
                        }
                      }
                      if ((DataSourceGroup.starrocks.includes(this.taskInfo.sinkType) || isRedis(this.taskInfo.sinkType)) && !DataSourceGroup.mq.includes(this.taskInfo.sourceType) && !table.hasPk) {
                        checked = false;
                        sinkTable = '';
                        selected = false;
                        action = [];
                        disabled = true;
                        disabledMsgArr.push('源端无主键');
                      } else if (this.taskInfo.sinkType === 'Hive') {
                        if (table.tableName.substr(0, 1) === '_') {
                          checked = false;
                          sinkTable = '';
                          selected = false;
                          action = [];
                          disabled = true;
                          disabledMsgArr.push('表名中含有"_"');
                        }
                      } else if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)
                        && !DataSourceGroup.mq.includes(this.taskInfo.sinkType)
                        && this.taskInfo.sinkTableList[item.sinkDb]
                        && !this.containsTable(this.taskInfo.sinkTableList[item.sinkDb], sinkTable)) {
                        checked = false;
                        sinkTable = '';
                        selected = false;
                        action = [];
                      } else if (isES(this.taskInfo.sinkType)
                        && this.taskInfo.sinkTableList[item.sinkDb]) {
                        this.taskInfo.sinkTableList[item.sinkDb].forEach((es) => {
                          if (es.tableName === sinkTable) {
                            if (es.indexMeta) {
                              if (es.indexMeta.numberOfShards) {
                                shards = parseInt(es.indexMeta.numberOfShards, 10);
                              }
                              if (es.indexMeta.numberOfReplicas) {
                                replication = parseInt(es.indexMeta.numberOfReplicas, 10);
                              }
                            }
                          }
                        });
                      } else if (DataSourceGroup.kudu.includes(this.taskInfo.sinkType) && !table.hasPk) {
                        checked = false;
                        sinkTable = '';
                        selected = false;
                        action = [];
                        disabled = true;
                        disabledMsgArr.push('源端无主键');
                      } else if ((this.taskInfo.type === 'CHECK' || this.taskInfo.type === 'REVISE') && !isRedis(this.taskInfo.sinkType) && !this.containsTable(this.getSinkList(item.sourceDb), sinkTable)) {
                        checked = false;
                        sinkTable = '';
                        selected = false;
                        action = [];
                      } else if (this.taskInfo.type === 'STRUCT_MIGRATION' && this.containsTable(this.getSinkList(item.sourceDb), sinkTable)) {
                        checked = false;
                        sinkTable = '';
                        selected = false;
                        action = [];
                        disabled = true;
                        disabledMsgArr.push('对端已存在源端表');
                      }
                      let partitions = 4;

                      if (DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
                        if (table.mqTopicPartitions) {
                          partitions = table.mqTopicPartitions;
                        }
                        let hasSame = false;
                        let
                          theTopic = null;
                        response.data.data.forEach((topic) => {
                          if (topic.tableName === sinkTable) {
                            hasSame = true;
                            theTopic = topic;
                          }
                        });

                        if (hasSame) {
                          partitions = theTopic.mqTopicPartitions;
                        }
                      }

                      if ((this.taskInfo.type === 'CHECK' || this.taskInfo.type === 'REVISE') && !isRedis(this.taskInfo.sinkType) && !this.containsTable(this.getSinkList(item.sourceDb), sinkTable)) {
                        checked = false;
                        sinkTable = '';
                        selected = false;
                        action = [];
                      }
                      if (this.taskInfo.editType === '2' && this.taskInfo.sinkTableList[theDb] && !this.containsTable(this.getSinkList(theDb), sinkTable)) {
                        sinkTable = '';
                      }

                      if (this.createReviseFromCheck) {
                        disabled = true;
                        disabledMsgArr.push('该订正任务通过校验任务创建');
                      }

                      const newItem = {
                        sourceTable: table.tableName,
                        disabledMsgArr,
                        action,
                        sinkTable,
                        commonRule: this.taskInfo.commonRule,
                        customSinkTable: '',
                        hasPk: table.hasPk,
                        isRds: this.taskInfo.sourceInstanceType === 'ALIBABA_CLOUD_HOSTED'
                          && this.taskInfo.sinkInstanceType === 'ALIBABA_CLOUD_HOSTED'
                          && DataSourceGroup.mysql.indexOf(this.taskInfo.sinkType) > -1,
                        index: i,
                        selected: selected,
                        db: item.sourceDb,
                        sourceDb: item.sourceDb,
                        sourceSchema: item.sourceSchema,
                        targetSchema: item.targetSchema,
                        sinkDb: item.sinkDb,
                        _checked: checked,
                        _disabled: disabled,
                        partitions,
                        shards,
                        replication,
                        needAutoCreated: Boolean(this.taskInfo.sinkTableList[theDb] && !this.containsTable(this.getSinkList(theDb), sinkTable) && this.taskInfo.editType !== '2')
                      };

                      if (this.type === 'edit' && type === 'new' && newItem._checked) {
                        newItem.originalAction = [];
                        if (!this.taskInfo.newData[newItem.sourceDb]) {
                          this.taskInfo.newData[newItem.sourceDb] = {
                            ...item,
                            new: true,
                            selectedTables: {}
                          };
                        }
                        this.taskInfo.newData[newItem.sourceDb].selectedTables[newItem.sourceTable] = newItem;
                      }

                      arr.push(newItem);
                    });
                    this.selectedTables[theDb] = arr;
                    this.filterTable[theDb] = {};
                    this.filterTable[theDb].tableFilterName = '';
                    this.filterTable[theDb].tableFilterCreate = ['autoCreated', 'noCreate'];
                    this.filterTable[theDb].tableFilterAction = {
                      INSERT: true,
                      UPDATE: true,
                      DELETE: true,
                      CREATE: true,
                      ALTER: true,
                      RENAME: true,
                      TRUNCATE: true,
                      DROP: true
                    };
                    this.filterTable[theDb].tableFilterType = '';
                    this.filterTable[theDb].tableFilterPk = ['hasPk', 'noPk'];
                    this.showList[theDb] = this.selectedTables[theDb];
                    this.hideSelectAll = false;
                    if (!this.defaultSelected && this.selectedTables[theDb].length > this.maxSelectAllCount) {
                      this.hideSelectAll = true;
                    }
                    this.pageData[theDb] = {
                      page: 1,
                      size: 20,
                      total: this.showList[theDb].length
                    };
                    this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
                      this.pageData[theDb].page * this.pageData[theDb].size);

                    // this.selectedIndex = this.taskInfo.dbMap.length - 1;
                    this.db = this.taskInfo.dbMap[this.selectedIndex];
                    this.showAddMap = false;
                    this.newAddMap = {};

                    setTimeout(() => {
                      item.selectedTables = that.selectedTables[theDb];
                      that.$set(that.selectedTables, index, index);
                      // that.index++;
                      // that.currentNum = '0';
                      // that.$set(that.selectedTables, index, index);
                      // that.index++;

                      if (this.type === 'new') {
                        this.forceUpdateView();
                      }
                    });
                  }
                  this.loading = false;
                  this.updateLoading(false);
                  this.showTableList = { ...this.showTableList };
                } catch (e) {
                  console.log('e', e);
                }
                if ((store.state.jobData || this.type === 'edit') && type !== 'new') {
                  this.parseSchemaData(this.taskInfo.jobData.schemaData, index);
                } else {
                  this.selectedTableCount();
                }
              });
          });
      } else {
        let theDb = item.sourceDb;

        if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
          theDb = item.sinkDb;
        }

        this.taskInfo.sinkTableList[theDb] = [];
        getTableList({
          host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
          privateHost: this.taskInfo.sourcePrivateHost,
          publicHost: this.taskInfo.sourcePublicHost,
          hostType: this.taskInfo.sourceHostType,
          type: this.taskInfo.sourceType,
          dbName: item.sourceDb,
          dataSourceId: this.taskInfo.sourceDataSourceId,
          clusterId: this.taskInfo.clusterId,
          tableSchema: item.sourceSchema,
          specifiedUserPassword: this.taskInfo.sourceSpecifiedUserPassword,
          rabbitMqVhost: this.taskInfo.srcRabbitMqVhost,
          rabbitExchange: this.taskInfo.srcRabbitExchange,
          sysTriggerDataSchema: this.taskInfo.advancedSetting.sysTriggerDataSchema,
          sysTriggerDataTable: this.taskInfo.advancedSetting.sysTriggerDataTable
        })
          .then((res) => {
            const { sourceType, sinkType } = this.taskInfo;
            this.loading = false;
            this.updateLoading(false);
            if (res.data.code === '1') {
              this.updateNextDisabled(false);
            }
            if (res.data.data.length < 1) {
              this.$Modal.warning({
                title: this.$t('ren-wu-chuang-jian-ti-shi'),
                content: this.$t('dang-qian-yuan-ku-itemsourcedb-wei-kong-ku-biao-de-shu-liang-wei-0-bu-zhi-chi-qian-yi', [item.sourceDb])
              });
            } else {
              if (type === 'new') {
                this.taskInfo.dbMap.push(this.newAddMap);
                this.taskInfo.compareSelectedDbs.push({
                  sourceDb: this.newAddMap.sourceDb,
                  sinkDb: this.newAddMap.sinkDb
                });
              }

              const arr = [];
              const that = this;
              res.data.data.forEach((table, i) => {
                let action = [];
                if (this.defaultSelected) {
                  if (!table.hasPk) {
                    if (this.hasUpdateAndDelete) {
                      if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
                        if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
                          action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
                        } else {
                          action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
                        }
                      } else {
                        action = ['INSERT', 'UPDATE', 'DELETE'];
                      }
                    } else {
                      if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
                        if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
                          action = ['INSERT', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
                        } else {
                          action = ['INSERT', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
                        }
                      } else {
                        action = ['INSERT'];
                      }
                    }
                  } else {
                    if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
                      if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
                        action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
                      } else {
                        action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
                      }
                    } else {
                      action = ['INSERT', 'UPDATE', 'DELETE'];
                    }
                  }
                }

                if (isMQ(sourceType)) {
                  action = [];
                }

                let checked = this.defaultSelected;
                let selected = this.defaultSelected;
                let sinkTable = table.tableName;
                let disabled = this.tableDisabled;
                const disabledMsgArr = [];

                if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
                  sinkTable = getTargetName(this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex], item.sinkDb, table, this.taskInfo);
                } else {
                  sinkTable = getTargetName(this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex], item.sourceDb, table, this.taskInfo);
                }
                if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
                  sinkTable = getTargetName(this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex], item.sinkDb, table, this.taskInfo);
                } else {
                  sinkTable = getTargetName(this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex], item.sourceDb, table, this.taskInfo);
                }
                if ((DataSourceGroup.starrocks.includes(this.taskInfo.sinkType) || isRedis(this.taskInfo.sinkType)) && !DataSourceGroup.mq.includes(this.taskInfo.sourceType) && !table.hasPk) {
                  checked = false;
                  selected = false;
                  action = [];
                  disabled = true;
                  disabledMsgArr.push('源端无主键');
                } else if (this.taskInfo.sinkType === 'Hive') {
                  if (table.tableName.substr(0, 1) === '_') {
                    checked = false;
                    selected = false;
                    sinkTable = '';
                    action = [];
                    disabled = true;
                    disabledMsgArr.push('表名中含有"_"');
                  }
                } else if ((this.taskInfo.type === 'CHECK' || this.taskInfo.type === 'REVISE') && !isRedis(this.taskInfo.sinkType) && !this.containsTable(this.getSinkList(item.sourceDb), sinkTable)) {
                  checked = false;
                  sinkTable = '';
                  selected = false;
                  action = [];
                  disabled = true;
                  disabledMsgArr.push('对端不存在对应表');
                } else if (this.taskInfo.type === 'STRUCT_MIGRATION' && this.containsTable(this.getSinkList(item.sourceDb), sinkTable)) {
                  checked = false;
                  sinkTable = '';
                  selected = false;
                  action = [];
                  disabled = true;
                  disabledMsgArr.push('对端已存在对应表');
                }

                if ((this.taskInfo.type === 'CHECK' || this.taskInfo.type === 'REVISE') && !isRedis(this.taskInfo.sinkType) && !this.containsTable(this.getSinkList(item.sourceDb), sinkTable)) {
                  checked = false;
                  sinkTable = '';
                  selected = false;
                  action = [];
                  disabled = true;
                  disabledMsgArr.push('对端不存在源端表');
                }

                if (this.createReviseFromCheck) {
                  disabled = true;
                  disabledMsgArr.push('该订正任务通过校验任务创建');
                }
                disabled = this.createReviseFromCheck || disabled;

                const newItem = {
                  sourceTable: table.tableName,
                  disabledMsgArr,
                  action,
                  sinkTable,
                  commonRule: this.taskInfo.commonRule,
                  customSinkTable: '',
                  hasPk: table.hasPk,
                  isRds: this.taskInfo.sourceInstanceType === 'ALIBABA_CLOUD_HOSTED'
                    && this.taskInfo.sinkInstanceType === 'ALIBABA_CLOUD_HOSTED'
                    && this.taskInfo.sinkType === 'MySQL',
                  index: i,
                  selected: selected,
                  db: item.sourceDb,
                  sourceDb: item.sourceDb,
                  sinkDb: item.sinkDb,
                  sourceSchema: item.sourceSchema,
                  targetSchema: item.targetSchema,
                  _checked: checked,
                  _disabled: disabled,
                  partitions: 4,
                  shards: 5,
                  replication: 1,
                  needAutoCreated: Boolean(this.taskInfo.sinkTableList[item.sourceDb] && !this.containsTable(this.getSinkList(item.sourceDb), table.tableName))
                };

                if (this.type === 'edit' && type === 'new') {
                  newItem.originalAction = [];
                  if (!this.taskInfo.newData[newItem.sourceDb]) {
                    this.taskInfo.newData[newItem.sourceDb] = {
                      ...item,
                      new: true,
                      selectedTables: {}
                    };
                  }
                  this.taskInfo.newData[newItem.sourceDb].selectedTables[newItem.sourceTable] = newItem;
                }

                arr.push(newItem);
              });

              this.selectedTables[theDb] = arr;
              this.filterTable[theDb] = {};
              this.filterTable[theDb].tableFilterName = '';
              this.filterTable[theDb].tableFilterCreate = ['autoCreated', 'noCreate'];
              this.filterTable[theDb].tableFilterAction = {
                INSERT: true,
                UPDATE: true,
                DELETE: true,
                CREATE: true,
                ALTER: true,
                RENAME: true,
                TRUNCATE: true,
                DROP: true
              };
              this.filterTable[theDb].tableFilterType = '';
              this.filterTable[theDb].tableFilterPk = ['hasPk', 'noPk'];
              this.filterTable[theDb].tableFilterColumn = '';
              this.showList[theDb] = this.selectedTables[theDb];
              this.hideSelectAll = false;
              if (!this.defaultSelected && this.selectedTables[theDb].length > this.maxSelectAllCount) {
                this.hideSelectAll = true;
              }
              this.pageData[theDb] = {
                page: 1,
                size: 20,
                total: this.showList[theDb].length
              };
              this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
                this.pageData[theDb].page * this.pageData[theDb].size);

              // this.selectedIndex = this.taskInfo.dbMap.length - 1;
              this.db = this.taskInfo.dbMap[this.selectedIndex];
              this.showAddMap = false;
              this.newAddMap = {};

              setTimeout(() => {
                item.selectedTables = that.selectedTables[theDb];
                that.$set(that.selectedTables, index, index);
                that.index++;
                that.currentNum = '0';
                that.$set(that.selectedTables, index, index);
                that.index++;
                this.forceUpdateView();
              });
            }
            this.showTableList = { ...this.showTableList };
          });
      }
    },
    init() {
      this.updateLoading(true);
      this.updateNextDisabled(true);
      this.remoteSearch = false;
      this.taskInfo.dbMap.forEach((item, index) => {
        // console.log(item.needAutoCreated);
        this._getTableList(item, index);
        if (this.type !== 'edit' || !item.actions) {
          if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
            if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
              item.actions = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
            } else {
              item.actions = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
            }
          } else {
            item.actions = ['INSERT', 'UPDATE', 'DELETE'];
          }
        }
      });
    },
    handleSelect(selectRow) {
      const index = this.selectedIndex;
      const db = this.$refs.tableFilterBody.$data.theDb;
      const { sourceType, sinkType } = this.taskInfo;
      if (this.type === 'edit') {
        if (this.taskInfo.reduceData[db] && this.taskInfo.reduceData[db].selectedTables[selectRow.sourceTable]) {
          delete this.taskInfo.reduceData[db].selectedTables[selectRow.sourceTable];
          if (Object.keys(this.taskInfo.reduceData[db].selectedTables).length === 0) {
            delete this.taskInfo.reduceData[db];
          }
        } else {
          if (!this.taskInfo.newData[db]) {
            this.taskInfo.newData[db] = {
              ...this.db,
              selectedTables: {}
            };
          }
          this.taskInfo.newData[db].selectedTables[selectRow.sourceTable] = selectRow;
        }
      }

      const item = selectRow;
      let action;

      if (!item.hasPk) {
        if (this.hasUpdateAndDelete) {
          if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
            if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
              action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
            } else {
              action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
            }
          } else {
            action = ['INSERT', 'UPDATE', 'DELETE'];
          }
        } else {
          if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
            if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
              action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
            } else {
              action = ['INSERT', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
            }
          } else {
            action = ['INSERT'];
          }
        }
      } else {
        if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
          if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
            action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
          } else {
            action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
          }
        } else {
          action = ['INSERT', 'UPDATE', 'DELETE'];
        }
      }

      if (isMQ(sourceType)) {
        action = [];
      }

      if ((!this.selectedTables[db][item.index].action || this.selectedTables[db][item.index].action.length < 1) && !item.hasInJob) {
        this.selectedTables[db][item.index].action = action;
      }

      if (this.selectedTables[db][item.index].hasInJob && this.selectedTables[db][item.index].preAction) {
        this.selectedTables[db][item.index].action = [...this.selectedTables[db][item.index].preAction];
      }
      this.selectedTables[db][item.index].selected = true;
      item._checked = true;
      const sinkTable = getTargetName(this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex], db, item, this.taskInfo);
      this.showList[db].forEach((show) => {
        if (item.sourceTable === show.sourceTable) {
          show._checked = true;
          show.selected = true;
          if ((this.taskInfo.type === 'CHECK' || this.taskInfo.type === 'REVISE') && !isRedis(this.taskInfo.sinkType) && !this.containsTable(this.getSinkList(db), sinkTable)) {
            show.sinkTable = '';
            show._chekced = false;
            show.selected = false;
            show.action = [];
          }
        }
      });
      this.handleSelectChange(index, db, item, getTargetName(this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex], db, item, this.taskInfo, 'create'));

      this.selectedTables[db].forEach((table) => {
        if (selectRow.sourceTable === table.sourceTable) {
          table._checked = true;
          table.selected = true;
        }
      });
      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];
      this.handleTableFilter(db, '', this.db, 'select');
      this.$refs.filterHeader.setCheckAllGroup(['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP']);
    },
    handleSelectCancel(selectRow) {
      const index = this.selectedIndex;
      const db = this.$refs.tableFilterBody.$data.theDb;
      if (this.taskInfo.newData[db] && this.taskInfo.newData[db].selectedTables[selectRow.sourceTable]) {
        delete this.taskInfo.newData[db].selectedTables[selectRow.sourceTable];
        if (Object.keys(this.taskInfo.newData[db].selectedTables).length === 0) {
          delete this.taskInfo.newData[db];
        }
      } else {
        if (!this.taskInfo.reduceData[db]) {
          this.taskInfo.reduceData[db] = {
            ...this.db,
            selectedTables: {}
          };
        }

        this.taskInfo.reduceData[db].selectedTables[selectRow.sourceTable] = selectRow;
      }

      this.selectedTables[db].forEach((item) => {
        if (item.sourceTable === selectRow.sourceTable) {
          if (item.action) {
            item.preAction = [...item.action];
            item.action = [];
          }
          // item.sinkTable = '';
          item.selected = false;
          item._checked = false;
        }
      });
      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];
      this.handleTableFilter(db, '', this.db, 'select');
      this.$refs.filterHeader.setCheckAllGroup([]);
      this.$nextTick(() => {
        this.showTableList[db].push('');
        this.showTableList[db].pop();
      });
    },
    handleSelectAll(index, db, type = 'all') {
      const { sourceType, sinkType } = this.taskInfo;
      this.showList[db].forEach((item, i) => {
        let pageDisabled = true;
        if (type === 'current' && (((this.pageData[db].page - 1) * this.pageData[db].size <= i) && (this.pageData[db].page * this.pageData[db].size > i))) {
          pageDisabled = false;
        }

        if (!item._disabled && (type === 'all' || (type === 'current' && !pageDisabled))) {
          let action;

          if (!item.hasPk) {
            if (this.hasUpdateAndDelete) {
              if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
                if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
                  action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
                } else {
                  action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
                }
              } else {
                action = ['INSERT', 'UPDATE', 'DELETE'];
              }
            } else {
              if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
                if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
                  action = ['INSERT', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
                } else {
                  action = ['INSERT', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
                }
              } else {
                action = ['INSERT'];
              }
            }
          } else {
            if (!JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
              if (isMySQL(this.taskInfo.sourceType) && isMySQL(this.taskInfo.sinkType)) {
                action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP'];
              } else {
                action = ['INSERT', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'RENAME', 'TRUNCATE'];
              }
            } else {
              action = ['INSERT', 'UPDATE', 'DELETE'];
            }
          }

          if (isMQ(sourceType)) {
            action = [];
          }

          if (this.taskInfo.sinkType === 'Hive' && item.sourceTable.substr(0, 1) === '_') {
            this.selectedTables[db][item.index].action = this.selectedTables[db][item.index].hasInJob && this.selectedTables[db][item.index].preAction
              ? [...this.selectedTables[db][item.index].preAction] : [];
            this.selectedTables[db][item.index].sinkTable = '';
          } else {
            if (DataSourceGroup.kudu.includes(this.taskInfo.sinkType) && !this.selectedTables[db][item.index].hasPk) {
              this.selectedTables[db][item.index].action = this.selectedTables[db][item.index].hasInJob && this.selectedTables[db][item.index].preAction
                ? [...this.selectedTables[db][item.index].preAction] : [];
            } else {
              this.selectedTables[db][item.index].action = this.selectedTables[db][item.index].hasInJob && this.selectedTables[db][item.index].preAction
                ? [...this.selectedTables[db][item.index].preAction] : action;
            }
            const sinkTable = getTargetName(this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex], db, item, this.taskInfo);
            if ((DataSourceGroup.kudu.includes(this.taskInfo.sinkType) && !this.selectedTables[db][item.index].hasPk)
              || (this.taskInfo.type === 'STRUCT_MIGRATION' && this.containsTable(this.getSinkList(db), sinkTable))) {
              this.selectedTables[db][item.index].selected = false;

              this.selectedTables[db][item.index].sinkTable = '';
            } else {
              if ((this.taskInfo.type === 'CHECK' || this.taskInfo.type === 'REVISE') && !isRedis(this.taskInfo.sinkType) && !this.containsTable(this.getSinkList(db), sinkTable)) {
                this.selectedTables[db][item.index].sinkTable = '';
                this.selectedTables[db][item.index]._chekced = false;
                this.selectedTables[db][item.index].selected = false;
                this.selectedTables[db][item.index].action = [];
              } else if (!this.containsTable(this.getSinkList(db), sinkTable) && this.taskInfo.editType === '2') {
                this.selectedTables[db][item.index].sinkTable = '';
              } else {
                this.selectedTables[db][item.index].sinkTable = sinkTable;
                if (!this.containsTable(this.getSinkList(db), sinkTable) && (isMongoDB(this.taskInfo.sourceType) && !isMongoDB(this.taskInfo.sinkType))) {
                  this.selectedTables[db][item.index].sinkTable = '';
                }
              }

              this.selectedTables[db][item.index].selected = true;
            }
            item._checked = true;
            this.selectedTables[db].forEach((table) => {
              if (table.sourceTable === item.sourceTable) {
                if ((DataSourceGroup.kudu.includes(this.taskInfo.sinkType) && !table.hasPk)
                  || (this.taskInfo.type === 'STRUCT_MIGRATION' && this.containsTable(this.getSinkList(db), sinkTable))) {
                  table._checked = false;
                } else {
                  table._checked = true;
                }
              }
            });
          }
          this.handleSelectChange(index, db, item, getTargetName(this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex], db, item, this.taskInfo, 'create'));

          if (this.taskInfo.reduceData[db] && this.taskInfo.reduceData[db].selectedTables[item.sourceTable]) {
            delete this.taskInfo.reduceData[db].selectedTables[item.sourceTable];
            if (Object.keys(this.taskInfo.reduceData[db].selectedTables).length === 0) {
              delete this.taskInfo.reduceData[db];
            }
          }
          if (!item.hasInJob) {
            if (!this.taskInfo.newData[db]) {
              this.taskInfo.newData[db] = {
                ...this.db,
                selectedTables: {}
              };
            }
            this.taskInfo.newData[db].selectedTables[item.sourceTable] = item;
          }
        }
      });

      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];

      // setTimeout(() => {
      //   that.forceUpdateView();
      // });
      this.handleTableFilter(db, '', this.db, 'select');
      this.$refs.filterHeader.setCheckAllGroup(['INSERT', 'UPDATE', 'DELETE']);
    },
    handleSelectAllCancel(index, db, type = 'all') {
      this.showList[db].forEach((show, i) => {
        let pageDisabled = true;
        if (type === 'current' && (((this.pageData[db].page - 1) * this.pageData[db].size <= i) && (this.pageData[db].page * this.pageData[db].size > i))) {
          pageDisabled = false;
        }

        if (!show._disabled && (type === 'all' || (type === 'current' && !pageDisabled))) {
          if (this.taskInfo.newData && this.taskInfo.newData[db] && this.taskInfo.newData[db].selectedTables[show.sourceTable]) {
            delete this.taskInfo.newData[db].selectedTables[show.sourceTable];
            if (Object.keys(this.taskInfo.newData[db].selectedTables).length === 0) {
              delete this.taskInfo.newData[db];
            }
          } else if (show._checked){
            if (this.taskInfo.reduceData) {
              if (!this.taskInfo.reduceData[db]) {
                this.taskInfo.reduceData[db] = {
                  ...this.taskInfo.dbMap[index],
                  selectedTables: {}
                };
              }
              this.taskInfo.reduceData[db].selectedTables[show.sourceTable] = show;
            }
          }
          show._checked = false;
          this.selectedTables[db].forEach((item) => {
            if (show.sourceTable === item.sourceTable) {
              item.preAction = [...item.action];
              // item.sinkTable = '';
              item.action = [];
              item.selected = false;
              item._checked = false;
            }
          });
        }
      });
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];

      this.forceUpdateView();
      this.handleTableFilter(db, '', this.db, 'select');
      this.$refs.filterHeader.setCheckAllGroup([]);
    },
    handleSelectChange(index, db, row, data) {
      let hasSame = false;
      const sinkTableList = this.getSinkList(db);

      if (sinkTableList) {
        sinkTableList.forEach((table) => {
          if (this.taskInfo.targetCaseSensitive === 'false') {
            if (data) {
              if (table.tableName.toLowerCase() === data.toLowerCase()) {
                hasSame = true;
              }
            }
          } else if (this.taskInfo.commonRule === 'LowerCase') {
            if (data) {
              if (table.tableName.toLowerCase() === data.toLowerCase()) {
                hasSame = true;
              }
            }
          } else if (this.taskInfo.commonRule === 'UpperCase') {
            if (data) {
              if (table.tableName.toUpperCase() === data.toUpperCase()) {
                hasSame = true;
              }
            }
          } else if (this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex].compareIgnoreCase) {
            if (table.tableName.toUpperCase() === data.toUpperCase()) {
              hasSame = true;
              data = table.tableName;
              this.selectedTables[db][row.index].sinkTable = data;
            }
          } else if (data === table.tableName
            || table.tableName === `cc_${this.taskInfo.sourceInstanceId}_${db}_${data}`) {
            if (DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
              hasSame = this.containsTable(sinkTableList, data);
            }

            // this.selectedTables[db][row.index].replication = table.replication;
            // this.selectedTables[db][row.index].shards = table.shards;
            // this.selectedTables[db][row.index].fieldMetaList = table.fieldMetaList;
          }
        });
      }
      hasSame = this.containsTable(this.getSinkList(db), row.sinkTable || data);

      this.selectedTables[db][row.index].needAutoCreated = !hasSame && !!data;
      // console.log(1);
      if (DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
        hasSame = false;
        let theTopic = null;

        this.getSinkList(this.db.sourceDb).forEach((topic) => {
          if (topic.tableName === data) {
            hasSame = true;
            theTopic = topic;
          }
        });

        if (hasSame) {
          this.selectedTables[db][row.index].partitions = theTopic.mqTopicPartitions;
        }
      } else if (DataSourceGroup.es.includes(this.taskInfo.sinkType)) {
        hasSame = false;
        let theIndex = null;

        this.getSinkList(this.db.sourceDb).forEach((indexItem) => {
          if (indexItem.tableName === data) {
            hasSame = true;
            theIndex = indexItem;
          }
        });
        if (hasSame) {
          // this.selectedTables[db][row.index].shards = theIndex.indexMeta.numberOfShards;
          // this.selectedTables[db][row.index].replication = theIndex.indexMeta.numberOfReplicas;
        }
      }
      // this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];
    },
    selectedTableCount() {
      console.log('selectedTableCount', this.taskInfo.dbMap);
      let selectCount = 0;
      let selectPageCount = 0;
      let allCount = 0;
      let allPageCount = 0;
      const theDb = this.getTheDb(this.db);
      this.taskInfo.dbMap[this.selectedIndex].indeterminate = false;
      this.taskInfo.dbMap[this.selectedIndex].selectedAll = false;
      if (this.showList[theDb]) {
        this.showList[theDb].forEach((table) => {
          allCount++;
          if (table._checked) {
            selectCount++
          }
        })

        if (allCount === selectCount) {
          this.taskInfo.dbMap[this.selectedIndex].selectedAll = true;
        } else if (selectCount === 0) {
          this.taskInfo.dbMap[this.selectedIndex].selectedAll = false;
        } else {
          this.taskInfo.dbMap[this.selectedIndex].indeterminate = true;
        }
      }

      this.taskInfo.dbMap[this.selectedIndex].selectedPage = false;
      this.taskInfo.dbMap[this.selectedIndex].pageIndeterminate = false;
      if (this.showTableList[theDb]) {
        this.showTableList[theDb].forEach((table) => {
          allPageCount++;
          if (table._checked) {
            selectPageCount++
          }
        })
        if (selectPageCount === allPageCount) {
          this.taskInfo.dbMap[this.selectedIndex].selectedPage = true;
        } else if (selectPageCount === 0) {
          this.taskInfo.dbMap[this.selectedIndex].selectedPage = false
        } else {
          this.taskInfo.dbMap[this.selectedIndex].pageIndeterminate = true;
        }
      }
    },
    handleActionChange(index, db) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }
      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[theDb];
    },
    forceUpdateView() {
      this.selectedTableCount();
      this.$set(this.selectedTables, this.index, this.index);
      this.index++;
      this.$set(this.selectedTables, this.index, this.index);
    },
    async updateNextStep() {
      try {
        const data = this.getDataJobData();
        const res = await tableCheck(data);
        this.updateLoading(false);
        console.log(res);

        let preCheckStatus = true;

        this.preCheckItemsErrorCount = 0;
        this.preCheckItemsSuccessCount = 0;
        this.preCheckItemsCheckedCount = 0;
        if (res.data.code === '1') {
          if (res.data.data) {
            console.log(res.data.data);
            res.data.data.map((item) => {
              if (item.checked) {
                this.preCheckItemsCheckedCount++;
                if (!item.success) {
                  preCheckStatus = false;
                  this.preCheckItemsErrorCount++;
                } else {
                  this.preCheckItemsSuccessCount++;
                }
              }
              return null;
            });
          }
          this.preCheckItems = res.data.data || [];
        } else {
          preCheckStatus = false;
        }

        if (preCheckStatus) {
          Object.keys(this.selectedTables)
            .forEach((item) => {
              if (this.selectedTables[item] instanceof Array) {
                this.selectedTables[item].forEach((table) => {
                  if (table.selected) {
                    if (JSON.parse(this.taskInfo.reSchemaMigration) && !table.hasInJob) {
                      table.needAutoCreated = true;
                    } else if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
                      table.needAutoCreated = !this.containsTable(this.taskInfo.sinkTableList[table.sinkDb], table.sinkTable);
                    } else {
                      if (DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
                        table.needAutoCreated = false;
                      } else {
                        table.needAutoCreated = !this.containsTable(this.taskInfo.sinkTableList[table.db], table.sinkTable);
                      }
                    }
                  }
                });
              }
            });
          this.taskInfo.selectedTables = this.selectedTables;
          this.taskInfo.filterTable = this.filterTable;
          if ((DataSourceGroup.noStruct.includes(this.taskInfo.sourceType) && DataSourceGroup.noStruct.includes(this.taskInfo.sinkType))) {
            this.updateStep(4);
          } else {
            this.updateStep(3);
          }
        } else {
          // this.showPreCheck = true;
        }
      } catch (e) {
        this.updateLoading(false);
      }
    },
    updatePrevStep() {
      this.taskInfo.selectedTables = this.selectedTables;
      this.taskInfo.filterTable = this.filterTable;
    },
    containsTable(list, table) {
      let contains = false;
      const targetTableName = table.toUpperCase();

      if (list) {
        list.forEach((item) => {
          // const sourceTableName = item.tableName.toUpperCase();
          // // console.log(sourceTableName);
          // if (this.taskInfo.targetCaseSensitive === 'false') {
          //   if (sourceTableName === targetTableName) {
          //     contains = true;
          //   }
          // } else if (this.taskInfo.commonRule === 'UpperCase') {
          //   if (sourceTableName === targetTableName) {
          //     contains = true;
          //   }
          // } else if (this.taskInfo.commonRule === 'LowerCase') {
          //   if (sourceTableName === targetTableName) {
          //     contains = true;
          //   }
          // } else if (item.tableName === table) {
          //   contains = true;
          // }
          if (item.tableName === table) {
            contains = true;
          }
        });
      }
      // console.log('table', targetTableName, contains);
      return contains;
    },
    handleTableFilter(db, schema, database, type) {
      this.calcHeight(db);
      let valueList = [];
      if (this.filterTable[db].tableFilterName) {
        valueList = this.filterTable[db].tableFilterName.split(';');
      }
      if (valueList.length > 1) {
        this.filterMode = 'exact';
      } else {
        this.filterMode = 'fuzzy';
      }

      this.showList[db] = [];
      let finalList = [];

      finalList = this.selectedTables[db].filter((item) => {
        if (!this.filterList[db]) {
          this.filterList[db] = [];
        }
        if (valueList.length < 1) {
          if ((this.filterList[db].indexOf('autoCreated') > -1 ? item.needAutoCreated : true)
            && (this.filterList[db].indexOf('noCreate') > -1 ? !item.needAutoCreated : true)
            && (this.filterList[db].indexOf('hasPk') > -1 ? item.hasPk : true)
            && (this.filterList[db].indexOf('noPk') > -1 ? !item.hasPk : true)
            && (this.filterList[db].indexOf('hasSelected') > -1 ? item.selected : true)
            && (this.filterList[db].indexOf('notSelected') > -1 ? !item.selected : true)) {
            return true;
          }
        }
        for (let i = 0; i < valueList.length; i++) {
          if ((this.filterList[db].indexOf('autoCreated') > -1 ? item.needAutoCreated : true)
            && (this.filterList[db].indexOf('noCreate') > -1 ? !item.needAutoCreated : true)
            && (this.filterList[db].indexOf('hasPk') > -1 ? item.hasPk : true)
            && (this.filterList[db].indexOf('noPk') > -1 ? !item.hasPk : true)
            && (this.filterList[db].indexOf('hasSelected') > -1 ? item.selected : true)
            && (this.filterList[db].indexOf('notSelected') > -1 ? !item.selected : true)
            && (valueList[i] ? this.filterMode === 'fuzzy' ? item.sourceTable.toLowerCase().indexOf(valueList[i].toLowerCase()) > -1 : item.sourceTable === valueList[i] : true) && valueList[i]) {
            return true;
          }
        }
        return false;
      });
      this.showList[db] = finalList;
      this.hideSelectAll = false;
      if (!this.defaultSelected && this.showList[db].length > this.maxSelectAllCount) {
        this.hideSelectAll = true;
      }
      if (this.filterList[db].indexOf('differPk') > -1) {
        this.filterByDifferPk(database, finalList);
      }

      if (this.filterList[db].indexOf('column') > -1) {
        this.filterByColumn(db, schema, finalList);
      }

      // this.showList = { ...this.showList };
      if (type !== 'select') {
        this.pageData[db].page = 1;
      }
      this.pageData[db].total = this.showList[db].length;
      this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
      this.showTableList = { ...this.showTableList };
      this.selectedTableCount();
    },
    handleBatchActionFilter(act, db, checked) {
      this.filterTable[db].tableFilterAction[act] = checked;
      this.showList[db].forEach((item) => {
        if (this.selectedTables[db][item.index].selected) {
          const action = [...item.action];
          if (!checked) {
            if (action.includes(act)) {
              action.splice(item.action.findIndex((a) => a === act), 1);
            }
          } else {
            if (!action.includes(act)) {
              action.push(act);
            }
          }

          this.selectedTables[db][item.index].action = action;
        }
      });

      this.showList[db].push('');
      this.showList[db].pop();
    },
    handlePageChange(db, page) {
      this.pageData[db].page = page;
      this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
      this.forceUpdateView();
    },
    handlePageSizeChange(db, size) {
      this.pageData[db].size = size;
      this.pageData[db].page = 1;
      this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
      this.forceUpdateView();
    },
    getTheDb(db) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }

      return theDb;
    },
    handleChangeFilterType(db, type) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }

      if (!this.filterList[theDb]) {
        this.filterList[theDb] = [];
      }
      if (this.filterTable[theDb].tableFilterType) {
        if (this.filterList[theDb].indexOf(type) === -1) {
          this.filterList[theDb].push(type);
        }
      }
      this.filterTable[theDb].tableFilterType = type;
      this.filterTable = { ...this.filterTable };

      const value = this.filterTable[theDb].tableFilterName;

      if (value || type) {
        this.handleTableFilter(theDb, db.sourceSchema, db);
      } else {
        this.showList[theDb] = this.selectedTables[theDb];
        this.pageData[theDb] = {
          page: 1,
          size: 20,
          total: this.showList[theDb].length
        };
        this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
          this.pageData[theDb].page * this.pageData[theDb].size);
      }
      // }
    },
    filterByDifferPk(db, finalList) {
      const list = [];
      const dbTablesMap = {};
      const sinkDbTablesMap = {};
      const sourceTableMetaList = [];
      const
        sinkTableMetaList = [];

      this.loading = true;
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }
      dbTablesMap[theDb] = [];
      sinkDbTablesMap[db.sinkDb] = [];
      this.selectedTables[theDb].forEach((table) => {
        if (table.selected) {
          dbTablesMap[theDb].push(table.sourceTable);
          sinkDbTablesMap[db.sinkDb].push(table.sinkTable);
          sourceTableMetaList.push({
            dbName: theDb,
            tableName: table.sourceTable,
            schemaName: db.sourceSchema
          });
          sinkTableMetaList.push({
            dbName: db.sinkDb,
            tableName: table.sinkTable,
            schemaName: db.targetSchema
          });
        }
      });
      batchListColumns({
        host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
        privateHost: this.taskInfo.sinkPrivateHost,
        publicHost: this.taskInfo.sinkPublicHost,
        hostType: this.taskInfo.targetHostType,
        type: this.taskInfo.sinkType,
        userName: this.taskInfo.sinkAccount,
        dbName: db.sinkDb,
        dbTablesMap: sinkDbTablesMap,
        dataSourceId: this.taskInfo.targetDataSourceId,
        tableSchema: '',
        tableMetas: sinkTableMetaList,
        clusterId: this.taskInfo.clusterId
      })
        .then((res) => {
          batchListColumns({
            host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
            privateHost: this.taskInfo.sourcePrivateHost,
            publicHost: this.taskInfo.sourcePublicHost,
            hostType: this.taskInfo.sourceHostType,
            type: this.taskInfo.sourceType,
            dbName: db.sourceDb,
            dbTablesMap,
            dataSourceId: this.taskInfo.sourceDataSourceId,
            tableSchema: '',
            tableMetas: sourceTableMetaList,
            clusterId: this.taskInfo.clusterId
          })
            .then((response) => {
              if (response.data.code === '1') {
                const sourceData = response.data.data;
                const
                  sinkData = res.data.data;

                if (Object.keys(sourceData.tableMetaDataMap[theDb]).length > 0) {
                  Object.keys(sourceData.tableMetaDataMap[theDb])
                    .forEach((key) => {
                      let sinkKey = '';
                      let sinkTable = '';
                      let
                        sourceKey = '';

                      sourceData.tableMetaDataMap[theDb][key].forEach((item) => {
                        // 两边都存在主键，但主键不一致
                        if (item.pk) {
                          sourceKey += item.columnName;
                        }
                      });
                      this.selectedTables[theDb].forEach((table) => {
                        if (table.sourceTable === key) {
                          sinkTable = table.sinkTable;
                        }
                      });
                      if (sinkData.tableMetaDataMap[db.sinkDb][sinkTable]) {
                        sinkData.tableMetaDataMap[db.sinkDb][sinkTable].forEach((column) => {
                          if (column.pk) {
                            sinkKey += column.columnName;
                          }
                        });
                      } else {
                        sinkKey = sourceKey;
                      }
                      if (sourceKey && sinkKey) {
                        if (sourceKey !== sinkKey) {
                          this.selectedTables[theDb].forEach((table) => {
                            if (table.sourceTable === key) {
                              list.push(table);
                            }
                          });
                        }
                      } else if (!sourceKey || !sinkKey) {
                        this.selectedTables[theDb].forEach((table) => {
                          if (table.sourceTable === key) {
                            list.push(table);
                          }
                        });
                      }
                      this.showList[theDb] = finalList.filter((item) => list.indexOf(item) > -1);
                      this.pageData[theDb].page = 1;
                      this.pageData[theDb].total = this.showList[theDb].length;
                      this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
                        this.pageData[theDb].page * this.pageData[theDb].size);
                      this.showTableList = { ...this.showTableList };
                    });
                } else {
                  this.showList[theDb] = [];
                  this.pageData[theDb].page = 1;
                  this.pageData[theDb].total = this.showList[theDb].length;
                  this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
                    this.pageData[theDb].page * this.pageData[theDb].size);
                  this.showTableList = { ...this.showTableList };
                }
              }
              this.loading = false;
            })
            .finally(() => {
              this.loading = false;
            });
        });
    },
    filterByColumn(db, schema, finalList) {
      let list = [];

      if (this.filterTable[db].tableFilterColumn) {
        this.loading = true;
        const dbTablesMap = {};
        const
          sourceTableMetaList = [];

        dbTablesMap[db] = [];
        this.selectedTables[db].forEach((table) => {
          dbTablesMap[db].push(table.sourceTable);
          sourceTableMetaList.push({
            dbName: db,
            tableName: table.sourceTable,
            schemaName: schema
          });
        });
        batchListColumns({
          host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
          privateHost: this.taskInfo.sourcePrivateHost,
          publicHost: this.taskInfo.sourcePublicHost,
          hostType: this.taskInfo.sourceHostType,
          type: this.taskInfo.sourceType,
          userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1
          && this.taskInfo.sourceAccountRole ? `${this.taskInfo.sourceAccount} as SYSDBA` : this.taskInfo.sourceAccount,
          dbName: db,
          dbTablesMap,
          dataSourceId: this.taskInfo.sourceDataSourceId,
          tableSchema: schema,
          tableMetas: sourceTableMetaList,
          clusterId: this.taskInfo.clusterId
        })
          .then((res) => {
            if (res.data.code === '1') {
              Object.keys(res.data.data.tableMetaDataMap[db])
                .forEach((key) => {
                  res.data.data.tableMetaDataMap[db][key].forEach((item) => {
                    if (item.columnName === this.filterTable[db].tableFilterColumn) {
                      this.selectedTables[db].forEach((table) => {
                        if (table.sourceTable === key) {
                          list.push(table);
                        }
                      });
                    }
                  });
                });
            }
            this.loading = false;
            this.showList[db] = finalList.filter((item) => list.indexOf(item) > -1);
            this.showList = { ...this.showList };
            this.pageData[db].page = 1;
            this.pageData[db].total = this.showList[db].length;
            this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
            this.showTableList = { ...this.showTableList };
          })
          .catch(() => {
            this.loading = false;
            this.showList[db] = finalList.filter((item) => list.indexOf(item) > -1);
            this.showList = { ...this.showList };
            this.pageData[db].page = 1;
            this.pageData[db].total = this.showList[db].length;
            this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
            this.showTableList = { ...this.showTableList };
          }).finally(() => {
            this.loading = false;
          });
      } else {
        list = this.selectedTables[db];
        this.showList[db] = finalList.filter((item) => list.indexOf(item) > -1);
        this.showList = { ...this.showList };
        this.pageData[db].page = 1;
        this.pageData[db].total = this.showList[db].length;
        this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
        this.showTableList = { ...this.showTableList };
      }
    },
    async handleRefresh() {
      this.loading = true;
      this.taskInfo.reduceData = {};
      this.taskInfo.newData = {};
      if (this.$route.name === 'taskEdit/process') {
        await this.getSourceAndSinkDbList(this.taskInfo.jobData);
      }
      this.getMappingRule(this.init);
    },
    handleCheckTableList(db, index) {
      this.db = db;
      this.selectedIndex = index;
      this.selectedTableCount();
    },
    handleDeleteFilter(i) {
      let theDb = this.db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = this.db.sinkDb;
      }

      this.filterList[theDb].splice(i, 1);
      this.filterTable[theDb].tableFilterType = '';
      this.handleTableFilter(theDb, this.db.sourceSchema, this.db);
    },
    handleClearFilter() {
      let theDb = this.db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = this.db.sinkDb;
      }

      this.filterList[theDb] = [];
      this.filterTable[theDb].tableFilterType = '';
      this.handleTableFilter(theDb, this.db.sourceSchema, this.db);
    },
    handleChangeMigrationType(data) {
      let theDb = this.db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = this.db.sinkDb;
      }

      this.migrationType = data;
      if (data === 'db') {
        this.selectedTables[theDb].forEach((item) => {
          item.sinkTable = `${this.taskInfo.sourceInstanceId}.${item.db}`;
        });
      } else {
        this.selectedTables[theDb].forEach((item) => {
          item.sinkTable = `${this.taskInfo.sourceInstanceId}.${item.db}.${item.sourceTable}`;
        });
      }
      this.showList[theDb] = this.selectedTables[theDb];
      this.pageData[theDb] = {
        page: 1,
        size: 20,
        total: this.showList[theDb].length
      };
      this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
        this.pageData[theDb].page * this.pageData[theDb].size);
    },
    handleDefaultTopic() {
      this.topicName = '';
      this.currentTopicName = '';
      this.handleChangeMigrationType('db');
    },
    handleSetTopic(type) {
      this.currentTopicName = '';
      this.showSetTopic = true;
      this.setDefaultValue = type === 'default';
    },
    handleShowSetTable() {
      this.showSetTable = true;
    },
    handleConfirmSetTopic() {
      this.topicName = this.currentTopicName;
      if (this.setDefaultValue) {
        this.taskInfo.dstMqDefaultTopic = this.topicName;
        this.getSinkList(this.db.sourceDb).forEach((item) => {
          if (item.tableName === this.topicName) {
            this.taskInfo.dstMqDefaultTopicPartitions = item.mqTopicPartitions;
          }
        });
        this.showSetTopic = false;
        this.taskInfo = { ...this.taskInfo };
        return;
      }
      let partitions = 4;
      let theDb = this.db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = this.db.sinkDb;
      }

      if (DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
        let hasSame = false;
        let
          theTopic = null;

        this.getSinkList(theDb).forEach((topic) => {
          if (topic.tableName === this.topicName) {
            hasSame = true;
            theTopic = topic;
          }
        });

        if (hasSame) {
          partitions = theTopic.mqTopicPartitions;
        }
      }
      this.selectedTables[theDb].forEach((item) => {
        if (item.selected) {
          item.sinkTable = this.topicName;
          item.needAutoCreated = !this.containsTable(this.getSinkList(theDb), item.sinkTable);
          item.partitions = partitions;
        }
      });
      this.showList[theDb] = this.selectedTables[theDb];
      this.pageData[theDb] = {
        page: 1,
        size: 20,
        total: this.showList[theDb].length
      };
      this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
        this.pageData[theDb].page * this.pageData[theDb].size);
      this.showSetTopic = false;
    },
    handleCancel() {
      this.showSetTopic = false;
      this.showSetShards = false;
      this.showMappingRule = false;
      this.showSetTable = false;
    },
    handleSetShards() {
      this.showSetShards = true;
    },
    handleSetPartitions() {

    },
    handleConfirmSetShards() {
      let theDb = this.db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = this.db.sinkDb;
      }

      this.selectedTables[theDb].forEach((item) => {
        if (item.needAutoCreated) {
          item.shards = this.shardsCount;
          item.replication = this.shardsReplication;
        }
      });
      this.showList[theDb] = this.selectedTables[theDb];
      this.pageData[theDb] = {
        page: 1,
        size: 20,
        total: this.showList[theDb].length
      };
      this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
        this.pageData[theDb].page * this.pageData[theDb].size);
      this.showSetShards = false;
    },
    handleConfirmSetTable() {
      Object.keys(this.selectedTables).forEach((db) => {
        if (Array.isArray(this.selectedTables[db])) {
          this.selectedTables[db].forEach((table) => {
            if(table.selected && !table.hasInJob) {
              if (this.selectedSetTableMethod === 'prefix') {
                table.sinkTable = `${this.setTablePrefixValue}${table.sinkTable}`;
              } else if (this.selectedSetTableMethod === 'suffix') {
                table.sinkTable = `${table.sinkTable}${this.setTableSuffixValue}`;
              } else if (this.selectedSetTableMethod === 'prefixAndSuffix') {
                table.sinkTable = `${this.setTablePrefixValue}${table.sinkTable}${this.setTableSuffixValue}`;
              }
            }
          })
        }
      })
      this.showSetTable = false;
    },
    remoteMethod(db, row, event) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }

      if (event.keyCode === 13) {
        queryIndex({
          host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
          privateHost: this.taskInfo.sinkPrivateHost,
          publicHost: this.taskInfo.sinkPublicHost,
          hostType: this.taskInfo.targetHostType,
          type: this.taskInfo.sinkType,
          userName: this.taskInfo.sinkAccount,
          dbName: theDb,
          dataSourceId: this.taskInfo.targetDataSourceId,
          tableSchema: '',
          clusterId: this.taskInfo.clusterId,
          regexIndexName: this.selectedTables[theDb][row.index].sinkTable
        })
          .then((res) => {
            if (res.data.code === '1') {
              this.searchLoading = false;
              const indexList = [];

              res.data.data.forEach((item) => {
                indexList.push({
                  tableName: item.indexMeta.indexName,
                  shards: item.indexMeta.numberOfShards,
                  replication: item.indexMeta.numberOfReplicas,
                  fieldMetaList: item.indexMeta.fieldMetaList
                });
              });
              this.taskInfo.sinkTableList[theDb] = indexList;
              this.selectedTables[theDb][row.index].visible = true;
              this.forceUpdateView();
              this.taskInfo.dbMap[row.index].selectedTables = this.selectedTables[theDb];
              this.handleTableFilter(theDb, '', this.db, 'select');
            }
          });
      }
    },
    handleChangeShards(index, db, row, data) {
      if (data) {
        this.selectedTables[db][row.index].shards = data;
      }
      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];
      this.handleTableFilter(db, '', this.db, 'select');
    },
    handleChangeReplication(index, db, row, data) {
      if (data) {
        this.selectedTables[db][row.index].replication = data;
      }
      this.forceUpdateView();
      this.taskInfo.dbMap[index].selectedTables = this.selectedTables[db];
      this.handleTableFilter(db, '', this.db, 'select');
    },
    handleChooseIndex(db, row, name) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }

      if (name) {
        this.selectedTables[theDb][row.index].sinkTable = name;
        this.selectedTables[theDb][row.index].visible = false;
        this.selectedTables[theDb][row.index].needAutoCreated = false;
        this.taskInfo.sinkTableList[theDb].forEach((item) => {
          if (item.tableName === name) {
            this.selectedTables[theDb][row.index].shards = item.shards;
            this.selectedTables[theDb][row.index].replication = item.replication;
          }
        });
      }
      this.forceUpdateView();
      this.taskInfo.dbMap[this.selectedIndex].selectedTables = this.selectedTables[theDb];
      this.handleTableFilter(theDb, '', this.db, 'select');
    },
    handleBlur(db, row) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }

      this.selectedTables[theDb][row.index].visible = false;
      this.forceUpdateView();
      this.taskInfo.dbMap[this.selectedIndex].selectedTables = this.selectedTables[theDb];
      this.handleTableFilter(theDb, '', this.db, 'select');
    },
    handleFocus(db, row) {
      let theDb = db.sourceDb;

      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        theDb = db.sinkDb;
      }

      this.selectedTables[theDb][row.index].visible = true;
      this.forceUpdateView();
      this.taskInfo.dbMap[this.selectedIndex].selectedTables = this.selectedTables[theDb];
      this.handleTableFilter(theDb, '', this.db, 'select');
    },
    updateShowTableList() {
      this.showTableList = { ...this.showTableList };
    },
    getMappingRule(func) {
      this.updateLoading(true);
      this.updateNextDisabled(true);

      tableMappingRule({
        dstDsId: this.taskInfo.targetDataSourceId,
        clusterId: this.taskInfo.clusterId,
        dstHostType: this.taskInfo.targetHostType,
        srcDsType: this.taskInfo.sourceType,
        dstDsType: this.taskInfo.sinkType
      })
        .then((res) => {
          this.updateLoading(false);
          if (res.data.code === '1') {
            this.updateNextDisabled(false);
            if (!this.taskInfo.tableMappingRule.length) {
              this.taskInfo.tableMappingRule = res.data.data;
              res.data.data.forEach((rule, index) => {
                if (rule.defaultCheck) {
                  this.taskInfo.tableMappingIndex = index;
                  this.taskInfo.selectedTableMapping = this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex].rule;
                }
              });
            }

            if (func) {
              func();
            }
          }
        });
    },
    handleShowMappingRule() {
      this.showMappingRule = true;
    },
    handleConfirmSetMappingRule() {
      this.showMappingRule = false;
      const theDb = this.taskInfo.dbMap[this.selectedIndex].sourceDb;

      if (this.selectedTables[theDb]) {
        this.selectedTables[theDb].forEach((table) => {
          if (!table.hasInJob) {
            table.sinkTable = getTargetName(this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex], this.taskInfo.dbMap[this.selectedIndex].sourceDb, table, this.taskInfo);
            table.needAutoCreated = Boolean(this.taskInfo.sinkTableList[theDb] && !this.containsTable(this.getSinkList(theDb), table.sinkTable));
          }
        });
        this.taskInfo.selectedTableMapping = this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex].rule;

        this.forceUpdateView();
        this.taskInfo.dbMap[this.selectedIndex].selectedTables = this.selectedTables[theDb];
        this.handleTableFilter(this.db.sourceDb, '', this.db, 'select');
      }
    },
    parseSchemaData(data, index) {
      console.log('parse schema');
      const { sourceType, sinkType } = this.taskInfo;
      const sourceSchemaJson = JSON.parse(data.sourceSchema); const
        mappingJson = JSON.parse(data.mappingConfig);
      const targetSchemaJson = data.targetSchema ? JSON.parse(data.targetSchema) : {};
      let tableMapping = {};
      let method = 'TABLE_TABLE';
      if (DataSourceGroup.es.includes(this.taskInfo.sinkType)) {
        if (isMQ(sourceType) && isES(sinkType)) {
          method = 'TOPIC_INDEX';
        } else if (isES(sourceType)) {
          method = 'TABLE_TABLE';
        } else {
          method = 'TABLE_INDEX';
        }
      } else if (DataSourceGroup.mq.includes(this.taskInfo.sinkType) && !isMQ(sourceType)) {
        method = 'TABLE_TOPIC';
      } else if (DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
        method = 'TABLE_KEYPREFIX';
      } else if (isMQ(sourceType) && isMQ(sinkType)) {
        method = 'TOPIC_TOPIC';
      } else if (isMQ(sourceType) && !isMQ(sinkType)) {
        method = 'TOPIC_TABLE';
      }

      mappingJson.forEach((mapping) => {
        try {
          if (mapping.method === method) {
            tableMapping = mapping.serializeMapping;
          }
        } catch (e) {
          console.log('e', e);
        }
      });

      // console.log('target', targetSchemaJson, sourceSchemaJson);

      let schema = [];

      if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType)) {
        schema = targetSchemaJson;
        if (isES(sourceType)) {
          schema = sourceSchemaJson;
        }
      } else {
        schema = sourceSchemaJson;
      }

      if (isMQ(sourceType) && isMQ(sinkType)) {
        schema = [];
      }

      this.taskInfo.dbMap.forEach((theDb) => {
        if (this.selectedTables[theDb.sourceDb]) {
          this.selectedTables[theDb.sourceDb].forEach((theTable) => {
            theTable.hasInJob = false;
            theTable.delete = false;
            theTable.sourceDb = theTable.db;
            schema.forEach((db) => {
              if (db.db === theDb.sourceDb) {
                let sameItem = null;
                let tables = [];
                if (db.schemas) {
                  tables = db.schemas[0].tables;
                } else if (db.tableSpaces) {
                  tables = db.tableSpaces[0].tables;
                } else if (db.collections) {
                  tables = db.collections;
                } else {
                  if (db.tables.length < 1) {
                    tables = sourceSchemaJson;
                  } else {
                    tables = db.tables;
                  }
                }

                tables.forEach((table) => {
                  if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType)) {
                    if (table.table === theTable.sinkTable || table.topic === theTable.sourceTable || table.indexName === theTable.sourceTable) {
                      sameItem = table;
                      if (sameItem) {
                        theTable.selected = true;
                        theTable._checked = true;
                        const sinkTable = this.getTargetTable(theTable, tableMapping, db);
                        theTable.sinkTable = sinkTable;
                        theTable.action = sameItem.actions;
                        if (!db.actions && !JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
                          theTable.action.push('CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP');
                        }

                        if (this.taskInfo.processType === 'edit') {
                          theTable.hasInJob = true;
                          if (sameItem.actions) {
                            theTable.preAction = [...sameItem.actions];
                            theTable.originalAction = [...sameItem.actions];
                          } else {
                            theTable.preAction = sameItem.actions;
                            theTable.originalAction = sameItem.actions;
                          }
                          theTable.originalSinkTable = theTable.sinkTable;
                        }
                      }
                    }
                  } else {
                    if (table.collection) {
                      table.table = table.collection;
                    }
                    if (table.table === theTable.sourceTable) {
                      sameItem = table;
                      if (sameItem) {
                        theTable.selected = true;
                        theTable._checked = true;
                        if (this.taskInfo.processType === 'edit') {
                          theTable.hasInJob = true;
                        }
                        if (this.taskInfo.schemaWhiteListLevel === 'DB' && DataSourceGroup.mq.includes(this.taskInfo.sinkType)) {
                          theTable.sinkTable = this.taskInfo.schemaData.defaultTopic;
                        } else {
                          const sinkTable = this.getTargetTable(theTable, tableMapping, db);
                          theTable.sinkTable = sinkTable;
                        }
                        theTable.action = sameItem.actions;
                        if (!db.actions && !JSON.parse(this.taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(this.taskInfo.sourceType)) {
                          theTable.action.push('CREATE', 'ALTER', 'RENAME', 'TRUNCATE', 'DROP');
                        }

                        if (this.taskInfo.processType === 'edit') {
                          theTable.preAction = [...sameItem.actions];
                          theTable.originalAction = [...sameItem.actions];
                          theTable.originalSinkTable = theTable.sinkTable;
                          theTable.originalSinkTable = theTable.sinkTable;
                        }
                      }
                    }
                  }
                });
                if (sameItem && this.taskInfo.type === 'STRUCT_MIGRATION') {
                  theTable.selected = false;
                  theTable._checked = false;
                  theTable.action = [];
                  theTable.originalAction = [];
                }
                if (!sameItem) {
                  theTable.hasInJob = false;
                  theTable.selected = false;
                  theTable._checked = false;
                  theTable.action = [];
                  theTable.originalAction = [];
                }
              }
            });

            if (isMQ(sourceType) && (isMQ(sinkType) || isES(sinkType))) {
              Object.keys(tableMapping).forEach((key) => {
                const topic = JSON.parse(key).value;
                let sinkTable = '';
                sinkTable = JSON.parse(tableMapping[key]).value;

                if (topic === theTable.sourceTable) {
                  theTable.sinkTable = sinkTable;
                  theTable.selected = true;
                  theTable._checked = true;
                  theTable.preAction = [...theTable.action];
                  theTable.originalAction = [...theTable.action];

                  if (this.taskInfo.processType === 'edit') {
                    theTable.hasInJob = true;
                    theTable.originalSinkTable = theTable.sinkTable;
                  }
                } else if (!theTable.hasInJob) {
                  theTable.hasInJob = false;
                  theTable.selected = false;
                  theTable._checked = false;
                  theTable.action = [];
                  theTable.originalAction = [];
                }
              });
            }
          });
          this.showList[theDb.sourceDb] = this.selectedTables[theDb.sourceDb];
          this.showTableList[theDb.sourceDb] = this.showList[theDb.sourceDb]
            ? this.showList[theDb.sourceDb].slice((this.pageData[theDb.sourceDb].page - 1) * this.pageData[theDb.sourceDb].size,
              this.pageData[theDb.sourceDb].page * this.pageData[theDb.sourceDb].size) : [];
        }
      });

      this.selectedTableCount();
    },
    getTargetTable(table, mapping, db) {
      let targetTable = table.sourceTable;
      Object.keys(mapping).forEach((theMapping) => {
        let theKey1 = {
          parent: {
            value: table.db
          },
          value: table.sourceTable
        };
        let theKey2 = {
          value: table.sourceTable,
          parent: {
            value: table.db
          }
        };
        const theKey3 = {
          value: table.sourceTable
        };
        if (db.schemas || db.tableSpaces) {
          if (db.tableSpaces) {
            theKey1 = {
              parent: {
                value: table.sourceSchema,
                parent: {
                  value: table.db
                }
              },
              value: table.sourceTable
            };
            theKey2 = {
              parent: {
                parent: {
                  value: table.db
                },
                value: table.sourceSchema
              },
              value: table.sourceTable
            };
          } else {
            theKey1 = {
              parent: {
                value: table.sourceSchema,
                parent: {
                  value: table.db
                }
              },
              value: table.sourceTable
            };
            theKey2 = {
              parent: {
                parent: {
                  value: table.db
                },
                value: table.sourceSchema
              },
              value: table.sourceTable
            };
          }
        }
        if (DataSourceGroup.noDb.indexOf(this.taskInfo.sourceType) > -1) {
          if (theMapping === JSON.stringify(theKey3)) {
            const theValue = JSON.parse(mapping[theMapping]);
            targetTable = theValue.value;
          }
        } else {
          if (theMapping === JSON.stringify(theKey1)) {
            const theValue = JSON.parse(mapping[theMapping]);
            targetTable = theValue.value;
          } else if (theMapping === JSON.stringify(theKey2)) {
            const theValue = JSON.parse(mapping[theMapping]);
            targetTable = theValue.value;
          }
        }
      });
      try {
        console.log('table.hasInJob', table.hasInJob);
        if (this.taskInfo.tableMappingRule.length && !table.hasInJob) {
          if (this.taskInfo.tableMappingRule[this.taskInfo.tableMappingIndex].rule === 'TO_LOWER_CASE') {
            targetTable = targetTable.toLowerCase();
          }
        }
      } catch (e) {
        console.log(e);
      }
      return targetTable;
    },
    editJobInitialSyncSwitch() {
      editJobInitialSyncSwitch({
        srcDsType: this.taskInfo.sourceType,
        dstDsType: this.taskInfo.sinkType
      }).then((response) => {
        if (response.data.code === '1') {
          this.taskInfo.showChooseInitialSync = response.data.data;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>

.new-add-db-item {
  padding: 10px 14px;
  border-bottom: 1px solid #DADADA;
}

.add-db-item {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #DADADA;
  border-top: 1px solid #DADADA;
}
.table-filter-container {
  margin-left: 280px;
  border-left: 1px solid #DADADA;
  overflow: hidden;
  position: relative;

  .table-filter-container-search {
    padding: 14px 20px;
    background-color: #FAFAFA;
  }

  .ivu-table-wrapper-with-border {
    border-right: none;
    border-left: none;
    border-bottom: none;
  }

  .table-filter-footer {
    margin-top: 50px;
    border-top: 1px solid #DADADA;
    background-color: #FAFAFA;
  }
}

.table-filter-filter-span {
  background-color: #FFE1E1;
  padding: 4px 6px;
  border-radius: 4px;
  margin-right: 8px;

  .ivu-icon {
    margin-left: 6px;
    cursor: pointer;
  }
}

.table-filter-filter-clear {
  margin-left: 6px;
  cursor: pointer;
}

</style>
